<template>
  <div class="rbxtree-wrapper">
    <div class="rbxtree-wrapper__top">
      <TablesTabs />
      <div
        class="rbxpier-color table-search"
        v-show="this.$store.state.isOpenSearch"
      >
        <div class="left-block">
          <div
            class="table-search__filter"
            :class="{ state: !isShowFilterDropDown }"
          >
            <p
              @click="isShowFilterDropDown = !isShowFilterDropDown"
              class="table-search__filter-checked"
            >
              {{ filterType }}
            </p>
            <div
              class="table-search__filter-dropdown"
              v-show="isShowFilterDropDown"
            >
              <ul>
                <li @click="changeSearchType" v-if="filterType !== 'user'">
                  user
                </li>
                <li @click="changeSearchType" v-if="filterType !== 'nickname'">
                  nickname
                </li>
                <li @click="changeSearchType" v-if="filterType !== 'order id'">
                  order id
                </li>
              </ul>
            </div>
          </div>
          <div class="table-search__input">
            <input
              type="text"
              v-model="searchInput"
              @keydown.enter="filterUser"
            />
            <div class="table-search__input-search" @click="filterUser">
              <img
                src="../../../assets/images/home/search-input.svg"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <MobileFilter
      v-show="$store.state.isOpenMobileFilter"
      :filterItems="['user', 'nickname', 'order id']"
      @changeFilter="changeFilter"
    />
    <MobileUserAndPayment v-show="$store.state.isOpenMobileFilterUserPayment" />
    <div class="rbxpier-search-panel home-search-m">
      <div class="left-filter-block">
        <div
          class="filter"
          @click="$store.state.isOpenMobileFilterUserPayment = true"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.78 9.88L17.78 7.38C17.6869 7.26215 17.5684 7.16679 17.4334 7.101C17.2983 7.03521 17.1502 7.00069 17 7H11V1C11 0.734784 10.8947 0.48043 10.7071 0.292893C10.5196 0.105357 10.2652 0 10 0C9.73479 0 9.48044 0.105357 9.2929 0.292893C9.10536 0.48043 9.00001 0.734784 9.00001 1V2H3.00001C2.8498 2.00069 2.70168 2.03521 2.56665 2.101C2.43161 2.16679 2.31313 2.26215 2.22001 2.38L0.220006 4.88C0.0804974 5.05655 0.00460815 5.27499 0.00460815 5.5C0.00460815 5.72501 0.0804974 5.94345 0.220006 6.12L2.22001 8.62C2.31313 8.73785 2.43161 8.83321 2.56665 8.899C2.70168 8.96479 2.8498 8.99931 3.00001 9H9.00001V18H7.00001C6.73479 18 6.48044 18.1054 6.2929 18.2929C6.10536 18.4804 6.00001 18.7348 6.00001 19C6.00001 19.2652 6.10536 19.5196 6.2929 19.7071C6.48044 19.8946 6.73479 20 7.00001 20H13C13.2652 20 13.5196 19.8946 13.7071 19.7071C13.8947 19.5196 14 19.2652 14 19C14 18.7348 13.8947 18.4804 13.7071 18.2929C13.5196 18.1054 13.2652 18 13 18H11V14H17C17.1502 13.9993 17.2983 13.9648 17.4334 13.899C17.5684 13.8332 17.6869 13.7379 17.78 13.62L19.78 11.12C19.9195 10.9435 19.9954 10.725 19.9954 10.5C19.9954 10.275 19.9195 10.0565 19.78 9.88ZM9.00001 7H3.48001L2.28001 5.5L3.48001 4H9.00001V7ZM16.52 12H11V9H16.52L17.72 10.5L16.52 12Z"
              fill="#069C38"
            />
          </svg>
        </div>
        <div class="filter" @click="$store.state.isOpenMobileFilter = true">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.4167 0.833496H3.58333C2.85398 0.833496 2.15451 1.12323 1.63878 1.63895C1.12306 2.15468 0.833328 2.85415 0.833328 3.5835V4.656C0.833197 5.03453 0.911214 5.40901 1.0625 5.756V5.811C1.192 6.10522 1.37544 6.3726 1.60333 6.59933L7.25 12.2093V18.2502C7.24968 18.4059 7.28908 18.5592 7.36446 18.6956C7.43984 18.8319 7.54873 18.9468 7.68083 19.0293C7.82671 19.1197 7.99504 19.1674 8.16666 19.1668C8.31016 19.166 8.45145 19.1314 8.57916 19.066L12.2458 17.2327C12.397 17.1565 12.5241 17.0399 12.6131 16.8959C12.7021 16.7519 12.7495 16.5861 12.75 16.4168V12.2093L18.36 6.59933C18.5879 6.3726 18.7713 6.10522 18.9008 5.811V5.756C19.0647 5.41172 19.1553 5.03713 19.1667 4.656V3.5835C19.1667 2.85415 18.8769 2.15468 18.3612 1.63895C17.8455 1.12323 17.146 0.833496 16.4167 0.833496ZM11.1825 11.1827C11.0975 11.2683 11.0303 11.3699 10.9847 11.4816C10.9391 11.5933 10.916 11.7129 10.9167 11.8335V15.8485L9.08333 16.7652V11.8335C9.08403 11.7129 9.0609 11.5933 9.01529 11.4816C8.96967 11.3699 8.90245 11.2683 8.8175 11.1827L3.95916 6.3335H16.0408L11.1825 11.1827ZM17.3333 4.50016H2.66666V3.5835C2.66666 3.34038 2.76324 3.10722 2.93515 2.93532C3.10706 2.76341 3.34021 2.66683 3.58333 2.66683H16.4167C16.6598 2.66683 16.8929 2.76341 17.0648 2.93532C17.2368 3.10722 17.3333 3.34038 17.3333 3.5835V4.50016Z"
              fill="#069C38"
            />
          </svg>
        </div>
      </div>
      <div class="home-search-m__input">
        <div class="lupa">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.7473 16.4825L14.4102 13.17C15.7056 11.5537 16.3329 9.50215 16.1631 7.43721C15.9934 5.37227 15.0396 3.45088 13.4978 2.06813C11.9559 0.685379 9.94333 -0.0536394 7.87376 0.00303437C5.80419 0.0597081 3.83498 0.907766 2.37103 2.37283C0.907077 3.83789 0.0596628 5.8086 0.00303206 7.87974C-0.0535986 9.95088 0.684859 11.965 2.06656 13.508C3.44826 15.051 5.36819 16.0056 7.43156 16.1754C9.49493 16.3453 11.5449 15.7175 13.16 14.4212L16.47 17.7337C16.5536 17.8181 16.6531 17.8851 16.7627 17.9308C16.8723 17.9765 16.9899 18 17.1086 18C17.2274 18 17.3449 17.9765 17.4546 17.9308C17.5642 17.8851 17.6636 17.8181 17.7473 17.7337C17.9094 17.5659 18 17.3416 18 17.1081C18 16.8747 17.9094 16.6504 17.7473 16.4825ZM8.11399 14.4212C6.8687 14.4212 5.65139 14.0516 4.61597 13.3593C3.58056 12.6669 2.77355 11.6828 2.297 10.5315C1.82045 9.38009 1.69577 8.11316 1.93871 6.89088C2.18165 5.6686 2.78131 4.54586 3.66186 3.66464C4.54241 2.78343 5.66429 2.18331 6.88565 1.94018C8.107 1.69706 9.37297 1.82184 10.5235 2.29875C11.674 2.77566 12.6573 3.58328 13.3491 4.61948C14.041 5.65568 14.4102 6.87392 14.4102 8.12015C14.4102 9.79129 13.7469 11.394 12.5661 12.5757C11.3853 13.7573 9.78386 14.4212 8.11399 14.4212Z"
              fill="#069C38"
            />
          </svg>
        </div>
        <input
          v-model="searchInput"
          type="text"
          placeholder="Поиск..."
          @keydown.enter="filterUser"
        />
      </div>
    </div>
    <div class="home-wrapper__table">
      <div class="table">
        <div class="loader" v-if="!isLoaded">
          <Loader />
        </div>
        <div class="table-wrapper" v-if="isLoaded">
          <table class="transfer-tb">
            <thead>
              <tr>
                <td>ORDER ID</td>
                <td>USER</td>
                <td>NICKNAME</td>
                <td>AMOUNT R$</td>
                <td>DATE</td>
                <td>STATUS</td>
              </tr>
            </thead>
            <tbody class="pier-scroll">
              <tr v-for="order in orders" :key="order._id">
                <td>{{ order._id }}</td>
                <td>{{ order.user_id }}</td>
                <td>{{ order.username }}</td>
                <td>{{ order.robux_amount }}</td>
                <td>{{ new Date(order.date).toUTCString() }}</td>
                <td>
                  <div
                    class="status"
                    :class="{
                      error: order.status === 'error',
                      ok: order.status === 'success',
                      new: order.status === 'new',
                    }"
                  >
                    <p v-if="order.status === 'error'">ERROR</p>
                    <p v-if="order.status === 'success'">SUCCESS</p>
                    <p v-if="order.status === 'new'">NEW</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="pagination" v-if="orders.length">
      <div class="pagination__info">
        <p>Страница {{ currentPage }} из {{ totalPages }}</p>
      </div>
      <div class="pagination__arrows">
        <div
          class="pagination__item rbxpier-pagination"
          @click="changePage('prev')"
        >
          <svg
            width="9"
            height="15"
            viewBox="0 0 9 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.812571 8.23957L6.7084 14.125C6.80524 14.2226 6.92045 14.3001 7.04739 14.353C7.17432 14.4059 7.31047 14.4331 7.44799 14.4331C7.5855 14.4331 7.72165 14.4059 7.84859 14.353C7.97552 14.3001 8.09073 14.2226 8.18757 14.125C8.38158 13.9298 8.49048 13.6658 8.49048 13.3906C8.49048 13.1154 8.38158 12.8514 8.18757 12.6562L3.03132 7.4479L8.18757 2.29165C8.38158 2.09648 8.49048 1.83247 8.49048 1.55728C8.49048 1.28208 8.38158 1.01807 8.18757 0.822903C8.0911 0.724478 7.97605 0.646175 7.8491 0.592533C7.72214 0.538891 7.58581 0.510976 7.44799 0.510404C7.31017 0.510976 7.17383 0.538891 7.04688 0.592533C6.91992 0.646175 6.80488 0.724478 6.7084 0.822903L0.812571 6.70832C0.706836 6.80586 0.622452 6.92425 0.564737 7.05602C0.507021 7.18779 0.477224 7.33009 0.477224 7.47394C0.477224 7.6178 0.507021 7.76009 0.564736 7.89187C0.622452 8.02364 0.706836 8.14202 0.812571 8.23957Z"
              fill="#B8B8B8"
            />
          </svg>
        </div>
        <div
          class="pagination__item rbxpier-pagination"
          @click="changePage('next')"
        >
          <svg
            width="9"
            height="15"
            viewBox="0 0 9 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.18743 8.23957L2.2916 14.125C2.19476 14.2226 2.07955 14.3001 1.95261 14.353C1.82568 14.4059 1.68953 14.4331 1.55201 14.4331C1.4145 14.4331 1.27835 14.4059 1.15141 14.353C1.02448 14.3001 0.909267 14.2226 0.81243 14.125C0.618419 13.9298 0.509521 13.6658 0.509521 13.3906C0.509521 13.1154 0.618419 12.8514 0.81243 12.6562L5.96868 7.4479L0.812429 2.29165C0.618418 2.09648 0.50952 1.83247 0.50952 1.55728C0.50952 1.28208 0.618418 1.01807 0.812429 0.822903C0.908903 0.724478 1.02395 0.646175 1.1509 0.592533C1.27786 0.538891 1.41419 0.510976 1.55201 0.510404C1.68983 0.510976 1.82617 0.538891 1.95312 0.592533C2.08008 0.646175 2.19512 0.724478 2.2916 0.822903L8.18743 6.70832C8.29316 6.80586 8.37755 6.92425 8.43526 7.05602C8.49298 7.18779 8.52278 7.33009 8.52278 7.47394C8.52278 7.6178 8.49298 7.76009 8.43526 7.89187C8.37755 8.02364 8.29316 8.14202 8.18743 8.23957Z"
              fill="#B8B8B8"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./transfer.scss";

import TablesTabs from "@/components/TablesTabs/TablesTabs";
import Loader from "@/components/Loader/Loader";
import MobileFilter from "@/components/MobileFilter/MobileFilter";
import MobileUserAndPayment from "@/components/mobileUserAndPayment/MobileUserAndPayment";

export default {
  name: "TransferPier",
  components: {
    Loader,
    MobileFilter,
    MobileUserAndPayment,
    TablesTabs,
  },
  data() {
    return {
      isFiltered: false,
      filterType: "user",
      isShowFilterDropDown: false,
      searchInput: null,
      orders: [],
      totalPages: 0,
      isLoaded: false,
      currentPage: 1,
    };
  },
  methods: {
    changePage(arrow) {
      if (arrow === "prev" && this.currentPage > 1) {
        this.currentPage--;
      }
      if (arrow === "next" && this.currentPage < this.totalPages) {
        this.currentPage++;
      }
      this.getData();
    },
    changeSearchType(event) {
      this.filterType = event.target.textContent.trim();
      this.isShowFilterDropDown = false;
    },
    checkResponse(response) {
      this.isLoaded = true;
      console.log(response);
      if (response.orders.length) {
        this.orders = response.orders;
        this.totalPages = response.total_pages;
      } else {
        this.orders = [];
      }
    },
    filterUser: function () {
      this.isFiltered = true;
      this.isLoaded = false;
      this.currentPage = 1;

      this.getData();
    },
    getData() {
      if (this.searchInput === null || this.searchInput === "") {
        this.isFiltered = false;
        fetch(
          `https://transfer.backend-pier.com/get_history?count=7&page=${this.currentPage}&is_admin=true&admin_key=${this.$store.state.admin_key}`
        )
          .then((res) => res.json())
          .then((data) => {
            this.checkResponse(data);
          });
      }
      if (this.filterType === "nickname" && this.searchInput) {
        fetch(
          `https://transfer.backend-pier.com/get_history?count=7&page=${this.currentPage}&is_admin=true&admin_key=${this.$store.state.admin_key}&username=${this.searchInput}`
        )
          .then((res) => res.json())
          .then((data) => {
            this.checkResponse(data);
          });
      }
      if (this.filterType === "user" && this.searchInput) {
        fetch(
          `https://transfer.backend-pier.com/get_history?count=7&page=${this.currentPage}&is_admin=true&admin_key=${this.$store.state.admin_key}&user_id=${this.searchInput}`
        )
          .then((res) => res.json())
          .then((data) => {
            this.checkResponse(data);
          });
      }
      if (this.filterType === "order id" && this.searchInput) {
        fetch(
          `https://transfer.backend-pier.com/get_history?count=7&page=${this.currentPage}&is_admin=true&admin_key=${this.$store.state.admin_key}&order_id=${this.searchInput}`
        )
          .then((res) => res.json())
          .then((data) => {
            this.checkResponse(data);
          });
      }
    },
    changeFilter(filter) {
      this.filterType = filter;
    },
  },
  mounted() {
    fetch(
      `https://transfer.backend-pier.com/get_history?count=7&page=1&is_admin=true&admin_key=${this.$store.state.admin_key}`
    )
      .then((res) => res.json())
      .then((data) => {
        this.checkResponse(data);
      });
  },
};
</script>
