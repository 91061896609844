<template>
  <div class="popup-exit">
    <div class="popup-exit__modal">
      <div class="popup-exit__modal-wrapper">
        <h2>ХОТИТЕ ВЫЙТИ ИЗ АККАУНТА?</h2>
        <div class="popup-exit__modal-wrapper__buttons">
          <div
            class="popup-exit__modal-wrapper__buttons__but yes"
            @click="exit"
          >
            <p>ДА</p>
          </div>
          <div
            class="popup-exit__modal-wrapper__buttons__but no"
            @click="$store.state.isOpenExitPopup = false"
          >
            <p>НЕТ</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./popupExit.scss";

export default {
  name: "PopupExit",
  methods: {
    exit() {
      localStorage.removeItem("bleachInfo");
      this.$store.state.isOpenExitPopup = false;
      this.$router.push("/");
    },
  },
};
</script>
