<template>
  <div class="home-wrapper__tabs">
    <div
      class="home-wrapper__tabs-block"
      v-if="$store.state.toggleSite === 'rbxtree'"
    >
      <div
        @click="$store.state.currentTub.rbxtree = 'topups'"
        class="home-wrapper__tabs-item rbxtree"
        :class="{
          'active-tab-tree': $store.state.currentTub.rbxtree === 'topups',
        }"
      >
        <p>TOPUPS</p>
      </div>
      <div
        @click="$store.state.currentTub.rbxtree = 'transfer'"
        class="home-wrapper__tabs-item rbxtree"
        :class="{
          'active-tab-tree': $store.state.currentTub.rbxtree === 'transfer',
        }"
      >
        <p>TRANSFER ORDERS</p>
      </div>
      <div
        @click="$store.state.currentTub.rbxtree = 'logpass'"
        class="home-wrapper__tabs-item rbxtree"
        :class="{
          'active-tab-tree': $store.state.currentTub.rbxtree === 'logpass',
        }"
      >
        <p>LOG+PASS</p>
      </div>
      <div
        @click="$store.state.currentTub.rbxtree = 'premium'"
        class="home-wrapper__tabs-item rbxtree"
        :class="{
          'active-tab-tree': $store.state.currentTub.rbxtree === 'premium',
        }"
      >
        <p>Premium</p>
      </div>
    </div>
    <div
      class="home-wrapper__tabs-block"
      v-if="$store.state.toggleSite === 'rbxpier'"
    >
      <div
        @click="$store.state.currentTub.rbxpier = 'topups'"
        class="home-wrapper__tabs-item rbxpier"
        :class="{
          'active-tab-pier': $store.state.currentTub.rbxpier === 'topups',
        }"
      >
        <p>TOPUPS</p>
      </div>
      <div
        @click="$store.state.currentTub.rbxpier = 'transfer'"
        class="home-wrapper__tabs-item rbxpier"
        :class="{
          'active-tab-pier': $store.state.currentTub.rbxpier === 'transfer',
        }"
      >
        <p>TRANSFER ORDERS</p>
      </div>
      <div
        @click="$store.state.currentTub.rbxpier = 'logpass'"
        class="home-wrapper__tabs-item rbxpier"
        :class="{
          'active-tab-pier': $store.state.currentTub.rbxpier === 'logpass',
        }"
      >
          <p>LOG+PASS</p>
      </div>
      <div
        @click="$store.state.currentTub.rbxpier = 'premium'"
        class="home-wrapper__tabs-item rbxpier"
        :class="{
          'active-tab-pier': $store.state.currentTub.rbxpier === 'premium',
        }"
      >
        <p>Premium</p>
      </div>
    </div>
    <div
      class="home-wrapper__tabs-block"
      v-if="$store.state.toggleSite === 'rbxsea'"
    >
      <div
        @click="$store.state.currentTub.rbxsea = 'topups'"
        class="home-wrapper__tabs-item rbxpier"
        :class="{
          'active-tab-pier': $store.state.currentTub.rbxsea === 'topups',
        }"
      >
        <p>TOPUPS</p>
      </div>
      <div
        @click="$store.state.currentTub.rbxsea = 'transfer'"
        class="home-wrapper__tabs-item rbxpier"
        :class="{
          'active-tab-pier': $store.state.currentTub.rbxsea === 'transfer',
        }"
      >
        <p>TRANSFER ORDERS</p>
      </div>
      <div
        @click="$store.state.currentTub.rbxsea = 'logpass'"
        class="home-wrapper__tabs-item rbxpier"
        :class="{
          'active-tab-pier': $store.state.currentTub.rbxsea === 'logpass',
        }"
      >
        <p>LOG+PASS</p>
      </div>
      <div
        @click="$store.state.currentTub.rbxsea = 'premium'"
        class="home-wrapper__tabs-item rbxpier"
        :class="{
          'active-tab-pier': $store.state.currentTub.rbxsea === 'premium',
        }"
      >
        <p>Premium</p>
      </div>
    </div>
  </div>
</template>

<script>
import "./tablesTabs.scss";

export default {
  name: "TablesTabs",
};
</script>
