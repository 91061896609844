<template>
  <div class="rbxtree-wrapper">
    <div class="rbxtree-wrapper__top">
      <TablesTabs />
      <div class="rbxpier-color table-search" v-show="this.$store.state.isOpenSearch">
        <div class="left-block">
          <div
            class="table-search__filter"
            :class="{ state: !isShowFilterDropDown }"
          >
            <p
              @click="isShowFilterDropDown = !isShowFilterDropDown"
              class="table-search__filter-checked"
            >
              {{ filterType }}
            </p>
            <div
              class="table-search__filter-dropdown"
              v-show="isShowFilterDropDown"
            >
              <ul>
                <li @click="changeSearchType" v-if="filterType !== 'user'">
                  user
                </li>
                <li @click="changeSearchType" v-if="filterType !== 'nickname'">
                  nickname
                </li>
                <li @click="changeSearchType" v-if="filterType !== 'order id'">
                  order id
                </li>
              </ul>
            </div>
          </div>
          <div class="table-search__input">
            <input
              type="text"
              v-model="searchInput"
              @keydown.enter="filterUser"
            />
            <div class="table-search__input-search" @click="filterUser">
              <img
                src="../../../assets/images/home/search-input.svg"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <MobileFilter
      v-show="$store.state.isOpenMobileFilter"
      :filterItems="['user', 'nickname', 'order id']"
      @changeFilter="changeFilter"
    />
    <MobileUserAndPayment v-show="$store.state.isOpenMobileFilterUserPayment" />
    <div class="home-search-m">
      <div class="left-filter-block">
        <div
          class="filter"
          @click="$store.state.isOpenMobileFilterUserPayment = true"
        >
          <img src="../../../assets/images/home/m-filter-2.svg" alt="filter" />
        </div>
        <div class="filter" @click="$store.state.isOpenMobileFilter = true">
          <img src="../../../assets/images/home/filter-icon.svg" alt="filter" />
        </div>
      </div>
      <div class="home-search-m__input">
        <img
          src="../../../assets/images/home/search-adapt.svg"
          alt=""
          @click="filterUser"
        />
        <input
          v-model="searchInput"
          type="text"
          placeholder="Поиск..."
          @keydown.enter="filterUser"
        />
      </div>
    </div>
    <div class="home-wrapper__table">
      <div class="table">
        <div class="loader" v-if="!isLoaded">
          <Loader />
        </div>
        <div class="table-wrapper" v-if="isLoaded">
          <table class="transfer-tb">
            <thead>
              <tr>
                <td>ORDER ID</td>
                <td>USER</td>
                <td>NICKNAME</td>
                <td>AMOUNT R$</td>
                <td>DATE</td>
                <td>STATUS</td>
              </tr>
            </thead>
            <tbody class="tree-scroll">
              <tr v-for="order in orders" :key="order._id">
                <td>{{ order._id }}</td>
                <td>{{ order.user_id }}</td>
                <td>{{ order.username }}</td>
                <td>{{ order.robux_amount }}</td>
                <td>{{ new Date(order.date).toUTCString() }}</td>
                <td>
                  <div
                    class="status"
                    :class="{
                      error: order.status === 'error',
                      ok: order.status === 'success',
                      new: order.status === 'new',
                    }"
                  >
                    <p v-if="order.status === 'error'">ERROR</p>
                    <p v-if="order.status === 'success'">SUCCESS</p>
                    <p v-if="order.status === 'new'">NEW</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="pagination" v-show="orders.length">
      <div class="pagination__info">
        <p>Страница {{ currentPage }} из {{ totalPages }}</p>
      </div>
      <div class="pagination__arrows">
        <div class="pagination__item" @click="changePage('prev')">
          <svg
            width="9"
            height="15"
            viewBox="0 0 9 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.812571 8.23957L6.7084 14.125C6.80524 14.2226 6.92045 14.3001 7.04739 14.353C7.17432 14.4059 7.31047 14.4331 7.44799 14.4331C7.5855 14.4331 7.72165 14.4059 7.84859 14.353C7.97552 14.3001 8.09073 14.2226 8.18757 14.125C8.38158 13.9298 8.49048 13.6658 8.49048 13.3906C8.49048 13.1154 8.38158 12.8514 8.18757 12.6562L3.03132 7.4479L8.18757 2.29165C8.38158 2.09648 8.49048 1.83247 8.49048 1.55728C8.49048 1.28208 8.38158 1.01807 8.18757 0.822903C8.0911 0.724478 7.97605 0.646175 7.8491 0.592533C7.72214 0.538891 7.58581 0.510976 7.44799 0.510404C7.31017 0.510976 7.17383 0.538891 7.04688 0.592533C6.91992 0.646175 6.80488 0.724478 6.7084 0.822903L0.812571 6.70832C0.706836 6.80586 0.622452 6.92425 0.564737 7.05602C0.507021 7.18779 0.477224 7.33009 0.477224 7.47394C0.477224 7.6178 0.507021 7.76009 0.564736 7.89187C0.622452 8.02364 0.706836 8.14202 0.812571 8.23957Z"
              fill="#B8B8B8"
            />
          </svg>
        </div>
        <div class="pagination__item" @click="changePage('next')">
          <svg
            width="9"
            height="15"
            viewBox="0 0 9 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.18743 8.23957L2.2916 14.125C2.19476 14.2226 2.07955 14.3001 1.95261 14.353C1.82568 14.4059 1.68953 14.4331 1.55201 14.4331C1.4145 14.4331 1.27835 14.4059 1.15141 14.353C1.02448 14.3001 0.909267 14.2226 0.81243 14.125C0.618419 13.9298 0.509521 13.6658 0.509521 13.3906C0.509521 13.1154 0.618419 12.8514 0.81243 12.6562L5.96868 7.4479L0.812429 2.29165C0.618418 2.09648 0.50952 1.83247 0.50952 1.55728C0.50952 1.28208 0.618418 1.01807 0.812429 0.822903C0.908903 0.724478 1.02395 0.646175 1.1509 0.592533C1.27786 0.538891 1.41419 0.510976 1.55201 0.510404C1.68983 0.510976 1.82617 0.538891 1.95312 0.592533C2.08008 0.646175 2.19512 0.724478 2.2916 0.822903L8.18743 6.70832C8.29316 6.80586 8.37755 6.92425 8.43526 7.05602C8.49298 7.18779 8.52278 7.33009 8.52278 7.47394C8.52278 7.6178 8.49298 7.76009 8.43526 7.89187C8.37755 8.02364 8.29316 8.14202 8.18743 8.23957Z"
              fill="#B8B8B8"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./transferSea.scss";

import TablesTabs from "@/components/TablesTabs/TablesTabs";
import Loader from "@/components/Loader/Loader";
import MobileFilter from "@/components/MobileFilter/MobileFilter";
import MobileUserAndPayment from "@/components/mobileUserAndPayment/MobileUserAndPayment";

export default {
  name: "TransferSea",
  components: {
    Loader,
    MobileFilter,
    MobileUserAndPayment,
    TablesTabs,
  },
  data() {
    return {
      isFiltered: false,
      filterType: "user",
      isShowFilterDropDown: false,
      searchInput: null,
      orders: [],
      totalPages: 0,
      isLoaded: false,
      currentPage: 1,
    };
  },
  //6038055ccdfe9837929e3b8f Sarkas00
  //6034f4bb1afbeb60c0848605 rbxsevadp
  //60608d91a0c1d6320cc5dd79
  methods: {
    changePage(arrow) {
      if (arrow === "prev" && this.currentPage > 1) {
        this.currentPage--;
      }
      if (arrow === "next" && this.currentPage < this.totalPages) {
        this.currentPage++;
      }
      this.getData();
    },
    changeSearchType(event) {
      this.filterType = event.target.textContent.trim();
      this.isShowFilterDropDown = false;
    },
    checkResponse(response) {
      this.isLoaded = true;
      console.log(response);
      if (response.orders.length) {
        this.orders = response.orders;
        this.totalPages = response.total_pages;
      } else {
        this.orders = [];
      }
    },
    filterUser: function () {
      this.isFiltered = true;
      this.isLoaded = false;
      this.currentPage = 1;

      this.getData();
    },
    getData() {
      if (this.searchInput === null || this.searchInput === "") {
        this.isFiltered = false;
        fetch(
          `https://pending.rbxsea.com/get_history?count=7&page=${this.currentPage}&is_admin=true&admin_key=${this.$store.state.admin_key}`
        )
          .then((res) => res.json())
          .then((data) => {
            this.checkResponse(data);
          });
      }
      if (this.filterType === "nickname" && this.searchInput) {
        fetch(
          `https://pending.rbxsea.com/get_history?count=7&page=${this.currentPage}&is_admin=true&admin_key=${this.$store.state.admin_key}&username=${this.searchInput}`
        )
          .then((res) => res.json())
          .then((data) => {
            this.checkResponse(data);
          });
      }
      if (this.filterType === "user" && this.searchInput) {
        fetch(
          `https://pending.rbxsea.com/get_history?count=7&page=${this.currentPage}&is_admin=true&admin_key=${this.$store.state.admin_key}&user_id=${this.searchInput}`
        )
          .then((res) => res.json())
          .then((data) => {
            this.checkResponse(data);
          });
      }
      if (this.filterType === "order id" && this.searchInput) {
        fetch(
          `https://pending.rbxsea.com/get_history?count=7&page=${this.currentPage}&is_admin=true&admin_key=${this.$store.state.admin_key}&order_id=${this.searchInput}`
        )
          .then((res) => res.json())
          .then((data) => {
            this.checkResponse(data);
          });
      }
    },
    changeFilter(filter) {
      this.filterType = filter;
    },
  },
  mounted() {
    fetch(
      `https://pending.rbxsea.com/get_history?count=7&page=1&is_admin=true&admin_key=${this.$store.state.admin_key}`
    )
      .then((res) => res.json())
      .then((data) => {
        this.checkResponse(data);
      });
  },
};
</script>
