<template>
  <div
    class="cookie-checker modal-wrapper"
    :class="{
      mobileUser: mobileVersion,
      'rbxpier-search-color': $store.state.toggleSite === 'rbxpier',
    }"
  >
    <div
      class="cookie-checker modal-wrapper__block"
      :class="{ 'second-stage': stage === 2 }"
    >
      <div
        class="modal-wrapper__block-close"
        :class="{
          'blue-arrow-bg':
            mobileVersion && $store.state.toggleSite === 'rbxpier',
        }"
        @click="$store.state.isOpenCookieChecker = false"
      >
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.9436 10.1263L4.03296 1.19612C3.64183 0.805056 3.11136 0.585356 2.55823 0.585356C2.0051 0.585356 1.47463 0.805056 1.08351 1.19612C0.692386 1.58719 0.472656 2.11758 0.472656 2.67063C0.472656 3.22368 0.692386 3.75408 1.08351 4.14515L10.0149 13.0545L1.08351 21.9639C0.692386 22.355 0.472656 22.8854 0.472656 23.4384C0.472656 23.9915 0.692386 24.5219 1.08351 24.913C1.47463 25.304 2.0051 25.5237 2.55823 25.5237C3.11136 25.5237 3.64183 25.304 4.03296 24.913L12.9436 15.9828L21.8543 24.913C22.0474 25.1076 22.2771 25.2621 22.5302 25.3675C22.7833 25.473 23.0548 25.5273 23.329 25.5273C23.6032 25.5273 23.8747 25.473 24.1278 25.3675C24.3809 25.2621 24.6106 25.1076 24.8037 24.913C24.9984 24.7199 25.1529 24.4902 25.2584 24.2371C25.3638 23.9841 25.4181 23.7126 25.4181 23.4384C25.4181 23.1643 25.3638 22.8928 25.2584 22.6398C25.1529 22.3867 24.9984 22.157 24.8037 21.9639L15.8723 13.0545L24.8037 4.14515C24.9984 3.95208 25.1529 3.72239 25.2584 3.46932C25.3638 3.21624 25.4181 2.94479 25.4181 2.67063C25.4181 2.39647 25.3638 2.12503 25.2584 1.87195C25.1529 1.61888 24.9984 1.38918 24.8037 1.19612C24.6106 1.00147 24.3809 0.846964 24.1278 0.741529C23.8747 0.636093 23.6032 0.581812 23.329 0.581812C23.0548 0.581812 22.7833 0.636093 22.5302 0.741529C22.2771 0.846964 22.0474 1.00147 21.8543 1.19612L12.9436 10.1263Z"
            fill="#2F2F2F"
          />
        </svg>
      </div>
      <div
        class="modal-wrapper__block-arrow"
        :class="{
          'blue-arrow-bg':
            mobileVersion && $store.state.toggleSite === 'rbxpier',
        }"
        v-if="stage === 2"
        @click="
          stage = 1;
          error.status = false;
          searchCookie = null;
        "
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.156344 13.2928C0.255541 13.5483 0.404281 13.7818 0.594028 13.9797L11.015 24.3878C11.2094 24.5819 11.4401 24.7359 11.694 24.8409C11.9479 24.9459 12.22 25 12.4948 25C13.0498 25 13.5821 24.7798 13.9746 24.3878C14.1689 24.1937 14.3231 23.9633 14.4282 23.7097C14.5334 23.4561 14.5875 23.1843 14.5875 22.9099C14.5875 22.3555 14.367 21.8239 13.9746 21.4319L7.09673 14.5834L22.9158 14.5834C23.4686 14.5834 23.9987 14.3641 24.3896 13.9737C24.7804 13.5833 25 13.0539 25 12.5018C25 11.9497 24.7804 11.4202 24.3896 11.0298C23.9987 10.6395 23.4686 10.4202 22.9158 10.4202L7.09673 10.4202L13.9746 3.57163C14.1699 3.37812 14.325 3.14789 14.4308 2.89423C14.5366 2.64056 14.5911 2.36848 14.5911 2.09369C14.5911 1.81889 14.5366 1.54681 14.4308 1.29314C14.325 1.03948 14.1699 0.809252 13.9746 0.615739C13.7808 0.420632 13.5503 0.265768 13.2963 0.160087C13.0424 0.0544057 12.7699 -2.74551e-06 12.4948 -2.72146e-06C12.2197 -2.6974e-06 11.9472 0.0544058 11.6933 0.160087C11.4393 0.265768 11.2088 0.420632 11.015 0.615739L0.594027 11.0238C0.40428 11.2218 0.25554 11.4552 0.156344 11.7108C-0.0521132 12.2176 -0.0521132 12.786 0.156344 13.2928Z"
            fill="#2F2F2F"
          />
        </svg>
      </div>
      <div
        class="modal-wrapper__block-content"
        v-if="stage === 1 && isLoaded === true"
      >
        <h1>COOKIE CHECKER</h1>
        <div class="modal-wrapper__block-content__form">
          <div class="modal-wrapper__block-content__form-label">
            <p>Cookie</p>
          </div>
          <div
            class="modal-wrapper__block-content__form-input"
            :class="{ 'error-input': error.status }"
          >
            <div class="modal-wrapper__block-content__form-input__img">
              <svg
                width="26"
                height="27"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M25.7684 11.1155C25.7026 10.7661 25.4191 10.5013 25.0664 10.459C24.713 10.4168 24.3758 10.6077 24.2296 10.9319C23.972 11.5006 23.4065 11.8963 22.7508 11.8955C22.0236 11.8971 21.4094 11.4064 21.2046 10.7328C21.0811 10.3217 20.6586 10.0779 20.241 10.1754C19.9843 10.2356 19.7413 10.2705 19.5 10.2705C17.7101 10.2673 16.2533 8.81045 16.25 7.02051C16.25 6.7792 16.2849 6.53626 16.3451 6.28032C16.4434 5.8627 16.1988 5.43938 15.7877 5.31588C15.1141 5.11195 14.6234 4.4977 14.625 3.77051C14.6242 3.11401 15.0199 2.54932 15.5886 2.29176C15.9128 2.14551 16.1038 1.80751 16.0615 1.45488C16.0192 1.10145 15.7544 0.817883 15.405 0.75207C14.6299 0.606633 13.8271 0.520508 13 0.520508C5.82887 0.522133 0.001625 6.34938 0 13.5205C0.001625 20.6916 5.82887 26.5189 13 26.5205C20.1711 26.5189 25.9984 20.6916 26 13.5205C26 12.6934 25.9139 11.8906 25.7684 11.1155ZM21.0397 21.5602C18.9767 23.6215 16.1379 24.8955 13 24.8955C9.86213 24.8955 7.02325 23.6215 4.96031 21.5602C2.899 19.4973 1.625 16.6584 1.625 13.5205C1.625 10.3826 2.899 7.54376 4.96031 5.48082C7.02325 3.41951 9.86213 2.14551 13 2.14551C13.1462 2.14551 13.2892 2.16095 13.4347 2.16745C13.1633 2.64032 13.0008 3.1847 13 3.77051C13.0016 4.98357 13.6744 6.02357 14.6551 6.57851C14.6396 6.72313 14.625 6.86776 14.625 7.02051C14.6266 9.7107 16.8098 11.8939 19.5 11.8955C19.6528 11.8955 19.7974 11.8809 19.942 11.8654C20.4969 12.8453 21.5361 13.5189 22.75 13.5205C23.3358 13.5197 23.8802 13.3572 24.3531 13.0858C24.3596 13.2304 24.375 13.3743 24.375 13.5205C24.375 16.6584 23.101 19.4973 21.0397 21.5602Z"
                    fill="white"
                  />
                  <path
                    d="M9.75 8.64551C10.6475 8.64551 11.375 7.91797 11.375 7.02051C11.375 6.12305 10.6475 5.39551 9.75 5.39551C8.85254 5.39551 8.125 6.12305 8.125 7.02051C8.125 7.91797 8.85254 8.64551 9.75 8.64551Z"
                    fill="white"
                  />
                  <path
                    d="M6.5 15.1455C7.39746 15.1455 8.125 14.418 8.125 13.5205C8.125 12.623 7.39746 11.8955 6.5 11.8955C5.60254 11.8955 4.875 12.623 4.875 13.5205C4.875 14.418 5.60254 15.1455 6.5 15.1455Z"
                    fill="white"
                  />
                  <path
                    d="M14.625 21.6455C15.5225 21.6455 16.25 20.918 16.25 20.0205C16.25 19.123 15.5225 18.3955 14.625 18.3955C13.7275 18.3955 13 19.123 13 20.0205C13 20.918 13.7275 21.6455 14.625 21.6455Z"
                    fill="white"
                  />
                  <path
                    d="M13.8125 15.1455C14.2612 15.1455 14.625 14.7817 14.625 14.333C14.625 13.8843 14.2612 13.5205 13.8125 13.5205C13.3638 13.5205 13 13.8843 13 14.333C13 14.7817 13.3638 15.1455 13.8125 15.1455Z"
                    fill="white"
                  />
                  <path
                    d="M20.3125 18.3955C20.7612 18.3955 21.125 18.0317 21.125 17.583C21.125 17.1343 20.7612 16.7705 20.3125 16.7705C19.8638 16.7705 19.5 17.1343 19.5 17.583C19.5 18.0317 19.8638 18.3955 20.3125 18.3955Z"
                    fill="white"
                  />
                  <path
                    d="M8.9375 20.0205C9.38623 20.0205 9.75 19.6567 9.75 19.208C9.75 18.7593 9.38623 18.3955 8.9375 18.3955C8.48877 18.3955 8.125 18.7593 8.125 19.208C8.125 19.6567 8.48877 20.0205 8.9375 20.0205Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect
                      width="26"
                      height="26"
                      fill="white"
                      transform="translate(0 0.520508)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <input
              type="text"
              v-model="searchCookie"
              @keydown.enter="checkCookie"
              placeholder="Enter Cookie"
            />
          </div>
          <small class="error-cookie" :class="{ 'show-err': !error.status }">{{
            error.errorMsg
          }}</small>
        </div>
        <div class="modal-wrapper__block-content__button">
          <button @click="checkCookie">search</button>
        </div>
      </div>
      <div class="loader" v-if="isLoaded === false">
        <Loader />
      </div>
      <div class="user__block" v-if="stage === 2 && isLoaded === true">
        <div class="user-name">
          <h1>COOKIE CHECKER</h1>
          <small>Username: <span>{{ cookieInfo.username }}</span></small>
        </div>
        <div class="cookie-table-wrapper">
          <table class="cookie-table">
            <thead>
              <tr>
                <td></td>
                <td class="header-td">day</td>
                <td class="header-td">WEEK</td>
                <td class="header-td">month</td>
                <td class="header-td">YEAR</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="header-td">GROUP</td>
                <td>{{ cookieInfo.Day.groupPayoutsTotal }} R$</td>
                <td>{{ cookieInfo.Week.groupPayoutsTotal }} R$</td>
                <td>{{ cookieInfo.Month.groupPayoutsTotal }} R$</td>
                <td>{{ cookieInfo.Year.groupPayoutsTotal }} R$</td>
              </tr>
              <tr>
                <td class="header-td">PREMIUM</td>
                <td>{{ cookieInfo.Day.premiumStipendsTotal }} R$</td>
                <td>{{ cookieInfo.Week.premiumStipendsTotal }} R$</td>
                <td>{{ cookieInfo.Month.premiumStipendsTotal }} R$</td>
                <td>{{ cookieInfo.Year.premiumStipendsTotal }} R$</td>
              </tr>
              <tr>
                <td class="header-td">pending</td>
                <td>{{ cookieInfo.Day.pendingRobuxTotal }} R$</td>
                <td>{{ cookieInfo.Week.pendingRobuxTotal }} R$</td>
                <td>{{ cookieInfo.Month.pendingRobuxTotal }} R$</td>
                <td>{{ cookieInfo.Year.pendingRobuxTotal }} R$</td>
              </tr>
              <tr>
                <td class="header-td">log+pass</td>
                <td>{{ cookieInfo.Day.currencyPurchasesTotal }} R$</td>
                <td>{{ cookieInfo.Week.currencyPurchasesTotal }} R$</td>
                <td>{{ cookieInfo.Month.currencyPurchasesTotal }} R$</td>
                <td>{{ cookieInfo.Year.currencyPurchasesTotal }} R$</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./cookieChecker.scss";

import Loader from "@/components/Loader/Loader";

export default {
  name: "CookieChecker",
  components: {
    Loader,
  },
  data() {
    return {
      mobileVersion: false,
      stage: 1,
      searchCookie: null,
      cookieInfo: [],
      isLoaded: true,
      error: {
        status: false,
        errorMsg: "Cookie error",
      },
    };
  },
  methods: {
    checkResponse(data) {
      this.isLoaded = true;
      console.log(data);
      if (!data["message"]) {
        this.stage = 2;
        this.error.status = false;
        this.cookieInfo = data;
      } else {
        this.error.status = true;
        this.error.errorMsg = "Cookie error";
      }
    },
    checkCookie() {
      if (this.searchCookie) {
        this.isLoaded = false;
        fetch(
          `https://topups.adaptivestrikes.com/api/v1/roblox/transactions?cookie=${this.searchCookie}`,
          {
            headers: {
              "SUPPORT-KEY": "sdf9u02309tj09sedjgmopisdmpogompsdgmpo",
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            this.checkResponse(data);
          });
      } else {
        this.error.status = true;
        this.error.errorMsg = "Пустое поле";
      }
    },
  },
  mounted() {
    if (document.body.clientWidth <= 1010) this.mobileVersion = true;
  },
};
</script>
