import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    currentPage: "",
    currentTub: {
      rbxtree: "topups",
      rbxpier: "topups",
      rbxsea: "topups",
    },
    userSearch_key: "sD23HAdhgASDnsdhaDW76wdgdxcdsgUsdSDGikd",
    admin_key: "sdf9u02309tj09sedjgmopisdmpogompsdgmpo",
    openBurgerMenu: false,
    isOpenSearch: true,
    isOpenExitPopup: false,
    isOpenUserSearch: false,
    isOpenPaymentSearch: false,
    isOpenCookieChecker: false,
    isOpenMobileFilter: false,
    isOpenMobileFilterUserPayment: false,
    toggleSite: "rbxtree",
    data: {
      bleachInfo: "kLq9ZpA;fG3hTj8mNwXzqR5sYb1u2Vd4Jc6e",
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
