var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"aside-menu",class:{ open: _vm.$store.state.openBurgerMenu === true }},[_c('div',{staticClass:"aside-menu__wrapper"},[_c('div',{staticClass:"aside-menu__wrapper-header"},[_c('h1',[_vm._v("Control panel")]),_c('div',{staticClass:"aside-menu__wrapper-header__img",class:{
          'rbxtree-bg': _vm.$store.state.toggleSite === 'rbxpier',
        },on:{"click":_vm.toggleSite}},[(_vm.$store.state.toggleSite === 'rbxtree')?_c('img',{attrs:{"src":require("../../assets/images/asideMenu/rbxpier.svg")}}):_c('img',{attrs:{"src":require("../../assets/images/home/rbx-tree.svg"),"alt":""}})])]),_c('div',{staticClass:"aside-menu__wrapper-content"},[(_vm.$store.state.toggleSite === 'rbxtree')?_c('ul',[_c('li',{class:{
            'active-link ': _vm.$store.state.currentTub.rbxtree === 'topups',
          },on:{"click":function($event){_vm.$store.state.currentTub.rbxtree = 'topups';
            _vm.$store.state.openBurgerMenu = false;}}},[_vm._v(" TOPUPS ")]),_c('li',{class:{
            'active-link ': _vm.$store.state.currentTub.rbxtree === 'transfer',
          },on:{"click":function($event){_vm.$store.state.currentTub.rbxtree = 'transfer';
            _vm.$store.state.openBurgerMenu = false;}}},[_vm._v(" TRANSFER ORDERS ")]),_c('li',{class:{
            'active-link ': _vm.$store.state.currentTub.rbxtree === 'logpass',
          },on:{"click":function($event){_vm.$store.state.currentTub.rbxtree = 'logpass';
            _vm.$store.state.openBurgerMenu = false;}}},[_vm._v(" LOG+PASS ")]),_c('li',{class:{
            'active-link ': _vm.$store.state.currentTub.rbxtree === 'premium',
          },on:{"click":function($event){_vm.$store.state.currentTub.rbxtree = 'premium';
            _vm.$store.state.openBurgerMenu = false;}}},[_vm._v(" PREMIUM ")])]):_c('ul',[_c('li',{class:{
            'active-link-pier': _vm.$store.state.currentTub.rbxpier === 'topups',
          },on:{"click":function($event){_vm.$store.state.currentTub.rbxpier = 'topups';
            _vm.$store.state.openBurgerMenu = false;}}},[_vm._v(" TOPUPS ")]),_c('li',{class:{
            'active-link-pier':
              _vm.$store.state.currentTub.rbxpier === 'transfer',
          },on:{"click":function($event){_vm.$store.state.currentTub.rbxpier = 'transfer';
            _vm.$store.state.openBurgerMenu = false;}}},[_vm._v(" TRANSFER ORDERS ")])])]),_c('div',{staticClass:"exit",on:{"click":function($event){_vm.$store.state.openBurgerMenu = false;
        _vm.$store.state.isOpenExitPopup = true;}}},[_c('img',{attrs:{"src":require("../../assets/images/asideMenu/exit.svg"),"alt":""}}),_c('p',[_vm._v("EXIT")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }