<template>
  <div class="auth">
    <div class="auth-wrapper">
      <div class="auth-wrapper__block">
        <h1>Авторизация</h1>
        <div class="auth-wrapper__block-content">
          <div class="auth-wrapper__block-content__input">
              <div class="auth-wrapper__block-content__input-label">
                <p>Ключ</p>
              </div>
            <div class="auth-wrapper__block-content__input password">
              <div class="auth-wrapper__block-content__input-wrapper">
                <div class="auth-wrapper__block-content__input-wrapper__key">
                  <img src="../../assets/images/auth/key.svg" alt="">
                </div>
                <input
                type="text"
                v-model="bleachInput"
                placeholder="Введите ключ"
                @keydown.enter="logIn"
              />
              </div>
            </div>
            <small :class="{ 'show-err': !errors.show }">{{
                errors.message
              }}</small>
          </div>
        </div>
        <div class="auth-wrapper__block-footer">
          <button @click="logIn">Войти</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./auth.scss";

export default {
  name: "Auth",
  data() {
    return {
      bleachInput: null,
      errors: {
        show: false,
        message: "Неверный ключ",
      },
    };
  },
  methods: {
    logIn() {
      if (this.bleachInput === this.$store.state.data.bleachInfo) {
        this.errors.show = false;
        localStorage.setItem("bleachInfo", this.bleachInput);
        this.$router.push("/dashboard");
      } else {
        this.errors.show = true;
      }
    },
  },
};
</script>
