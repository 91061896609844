<template>
  <aside
    class="aside-menu"
    :class="{ open: $store.state.openBurgerMenu === true }"
  >
    <div class="aside-menu__wrapper">
      <div class="aside-menu__wrapper-header">
        <h1>Control panel</h1>
        <div
          class="aside-menu__wrapper-header__img"
          :class="{
            'rbxtree-bg': $store.state.toggleSite === 'rbxpier',
          }"
          @click="toggleSite"
        >
          <img
            v-if="$store.state.toggleSite === 'rbxtree'"
            src="../../assets/images/asideMenu/rbxpier.svg"
          />
          <img src="../../assets/images/home/rbx-tree.svg" v-else alt="" />
        </div>
      </div>
      <div class="aside-menu__wrapper-content">
        <ul v-if="$store.state.toggleSite === 'rbxtree'">
          <li
            :class="{
              'active-link ': $store.state.currentTub.rbxtree === 'topups',
            }"
            @click="
              $store.state.currentTub.rbxtree = 'topups';
              $store.state.openBurgerMenu = false;
            "
          >
            TOPUPS
          </li>
          <li
            @click="
              $store.state.currentTub.rbxtree = 'transfer';
              $store.state.openBurgerMenu = false;
            "
            :class="{
              'active-link ': $store.state.currentTub.rbxtree === 'transfer',
            }"
          >
            TRANSFER ORDERS
          </li>
          <li
            @click="
              $store.state.currentTub.rbxtree = 'logpass';
              $store.state.openBurgerMenu = false;
            "
            :class="{
              'active-link ': $store.state.currentTub.rbxtree === 'logpass',
            }"
          >
            LOG+PASS
          </li>
          <li
            @click="
              $store.state.currentTub.rbxtree = 'premium';
              $store.state.openBurgerMenu = false;
            "
            :class="{
              'active-link ': $store.state.currentTub.rbxtree === 'premium',
            }"
          >
            PREMIUM
          </li>
        </ul>
        <ul v-else>
          <li
            :class="{
              'active-link-pier': $store.state.currentTub.rbxpier === 'topups',
            }"
            @click="
              $store.state.currentTub.rbxpier = 'topups';
              $store.state.openBurgerMenu = false;
            "
          >
            TOPUPS
          </li>
          <li
            :class="{
              'active-link-pier':
                $store.state.currentTub.rbxpier === 'transfer',
            }"
            @click="
              $store.state.currentTub.rbxpier = 'transfer';
              $store.state.openBurgerMenu = false;
            "
          >
            TRANSFER ORDERS
          </li>
        </ul>
      </div>
      <div
        class="exit"
        @click="
          $store.state.openBurgerMenu = false;
          $store.state.isOpenExitPopup = true;
        "
      >
        <img src="../../assets/images/asideMenu/exit.svg" alt="" />
        <p>EXIT</p>
      </div>
    </div>
  </aside>
</template>

<script>
import "./asideMenu.scss";

export default {
  name: "AsideMenu",
  data() {
    return {
      // rbxtree: {
      //   tab: "topups",
      // },
      // rbxpier: {
      //   tab: "topups",
      // },
    };
  },
  methods: {
    toggleSite() {
      this.$store.state.toggleSite === "rbxtree"
        ? (this.$store.state.toggleSite = "rbxpier")
        : (this.$store.state.toggleSite = "rbxtree");

      this.$store.state.openBurgerMenu = false;
    },
  },
};
</script>
