<template>
  <div class="new-filter__wrapper">
    <div class="new-filter__wrapper-modal" :class="{'rbxpier-color-m': $store.state.toggleSite === 'rbxpier'}">
      <div
        class="new-filter__wrapper-modal__close"
        @click="$store.state.isOpenMobileFilterUserPayment = false"
      >
        <img src="../../assets/images/mobileUserAndPayment/close.svg" alt="" />
      </div>
      <div class="new-filter__wrapper-modal__content">
        <h1>DETAILED SEARCH</h1>
        <div class="new-filter__wrapper-modal__content-buttons">
          <div
            class="new-filter__wrapper-modal__content-buttons__item"
            @click="
              $store.state.isOpenUserSearch = true;
              $store.state.isOpenMobileFilterUserPayment = false;
            "
          >
            <img
              src="../../assets/images/mobileUserAndPayment/user.svg"
              alt=""
            />
            <p>User Search</p>
          </div>
          <div
            class="new-filter__wrapper-modal__content-buttons__item"
            @click="
              $store.state.isOpenPaymentSearch = true;
              $store.state.isOpenMobileFilterUserPayment = false;
            "
          >
            <img
              src="../../assets/images/mobileUserAndPayment/payment.svg"
              alt=""
            />
            <p>PAYMENT Search</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./mobileUserAndPayment.scss";

export default {
  name: "MobileUserAndPayment",
};
</script>
