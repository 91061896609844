<template>
  <div class="mobile-filter">
    <div class="mobile-filter__modal">
      <div class="mobile-filter__modal-content">
        <h1>Искать по ГРАФЕ</h1>
        <ul>
          <li
            v-for="(item, index) in filterItems"
            :key="index"
            @click="checkedFilter(item, index)"
            :class="{ 'checked-filter': index === currentChecked }"
          >
            {{ item }}
          </li>
        </ul>
        <div class="mobile-filter__modal-content__btn">
          <button @click="applyFilter">Применить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./mobileFilter.scss";

export default {
  name: "MobileFilter",
  data() {
    return {
      currentChecked: 0,
      filterType: "",
    };
  },
  props: {
    filterItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    checkedFilter(item, index) {
      // console.log(item, index);
      this.currentChecked = index;
      this.filterType = item.trim();
    },
    applyFilter() {
      this.$store.state.isOpenMobileFilter = false;
      this.$emit("changeFilter", this.filterType);
    },
  },
  mounted() {
    this.filterType = this.filterItems[0];
  },
};
</script>
