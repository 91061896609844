<template>
  <div class="wrapper">
    <AsideMenu />
    <div id="app" :class="{ offset: $store.state.openBurgerMenu }">
      <div
        :class="{ overlay: $store.state.openBurgerMenu }"
        @click="$store.state.openBurgerMenu = false"
      ></div>
      <router-view />
    </div>
  </div>
</template>

<script>
import "@/scss/_reset.scss";
import "@/scss/fonts/font.css";
import "@/scss/_main.scss";

// import Navigation from '@/components/Navigation/Navigation';
import AsideMenu from "@/components/AsideMenu/AsideMenu";

export default {
  name: "App",
  components: {
    AsideMenu,
    // Navigation
  },
  mounted() {
    window.addEventListener("storage", () => {
      console.log('change')
      localStorage.removeItem("bleachInfo");
      this.$store.state.isOpenExitPopup = false;
      this.$router.push("/");
    });
  },
};
</script>
