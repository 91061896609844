/* eslint-disable */
<template>
  <div class="support">
    <Navigation />
    <CookieChecker v-if="$store.state.isOpenCookieChecker" />
    <PopupExit v-if="$store.state.isOpenExitPopup" />
    <UserSearch v-if="$store.state.isOpenUserSearch" />
    <PaymentSearch v-if="$store.state.isOpenPaymentSearch" />
    <div class="home-wrapper">
      <div class="support-chat__full-image" v-if="showFullImage">
        <div class="support-chat__full-image__wrapper">
          <div
            class="support-chat__full-image__wrapper__close"
            @click="closeFullImage"
          >
            <img src="../../assets/images/support/close.svg" alt="close" />
          </div>
          <img :src="fullImage" alt="" />
        </div>
      </div>
      <div class="support-main"
          :class="{
            'rbxpier-support-theme': $store.state.toggleSite === 'rbxpier',
            'starpets-support-theme': $store.state.toggleSite === 'starpets'
          }">
        <div class="chat-init-loader" v-if="loading">
          <Loader/>
        </div>
        <div class="support-main__dialogs">
          <div class="support-main__dialogs-header">
            <h1 v-if="$store.state.toggleSite === 'rbxtree'">Диалоги RobuxTree</h1>
            <h1 v-else-if="$store.state.toggleSite === 'rbxpier'">Диалоги RobuxPier</h1>
            <h1 v-else-if="$store.state.toggleSite === 'starpets'">Диалоги Starpets</h1>
            <div class="support-main__dialogs-header__search">
              <div class="support-main__dialogs-header__search-filter">
                <div class="support-main__dialogs-header__search-filter__icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 1.80878e-07 2.20435 1.80878e-07 3V4.17C-0.000143207 4.58294 0.0849664 4.99147 0.25 5.37V5.43C0.39128 5.75097 0.591392 6.04266 0.84 6.29L7 12.41V19C6.99966 19.1699 7.04264 19.3372 7.12487 19.4859C7.20711 19.6346 7.32589 19.7599 7.47 19.85C7.62914 19.9486 7.81277 20.0006 8 20C8.15654 19.9991 8.31068 19.9614 8.45 19.89L12.45 17.89C12.6149 17.8069 12.7536 17.6798 12.8507 17.5227C12.9478 17.3656 12.9994 17.1847 13 17V12.41L19.12 6.29C19.3686 6.04266 19.5687 5.75097 19.71 5.43V5.37C19.8888 4.99443 19.9876 4.58578 20 4.17V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM11.29 11.29C11.1973 11.3834 11.124 11.4943 11.0742 11.6161C11.0245 11.7379 10.9992 11.8684 11 12V16.38L9 17.38V12C9.00076 11.8684 8.97554 11.7379 8.92577 11.6161C8.87601 11.4943 8.80268 11.3834 8.71 11.29L3.41 6H16.59L11.29 11.29ZM18 4H2V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V4Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div
                  class="support-main__dialogs-header__search-filter__checked"
                  @click="isOpenFilter = !isOpenFilter"
                >
                  <div
                    class="
                      support-main__dialogs-header__search-filter__checked-wrapper
                    "
                  >
                    <p>{{ filterTypeMap[filterType] }}</p>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.99995 4.71018L2.45995 1.17018C2.27259 0.983927 2.01913 0.879386 1.75495 0.879386C1.49076 0.879386 1.23731 0.983927 1.04995 1.17018C0.95622 1.26314 0.881825 1.37374 0.831057 1.4956C0.780288 1.61746 0.75415 1.74817 0.75415 1.88018C0.75415 2.01219 0.780288 2.1429 0.831057 2.26476C0.881826 2.38661 0.95622 2.49722 1.04995 2.59018L5.28995 6.83018C5.38291 6.92391 5.49351 6.9983 5.61537 7.04907C5.73723 7.09984 5.86794 7.12598 5.99995 7.12598C6.13196 7.12598 6.26267 7.09984 6.38453 7.04907C6.50638 6.9983 6.61699 6.92391 6.70995 6.83018L10.9999 2.59018C11.0926 2.49674 11.166 2.38592 11.2157 2.26408C11.2655 2.14225 11.2907 2.01178 11.2899 1.88018C11.2907 1.74857 11.2655 1.61811 11.2157 1.49627C11.166 1.37443 11.0926 1.26362 10.9999 1.17018C10.8126 0.983927 10.5591 0.879386 10.2949 0.879386C10.0308 0.879386 9.77731 0.983927 9.58995 1.17018L5.99995 4.71018Z"
                        fill="#2F2F2F"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  class="
                    support-main__dialogs-header__search-filter__checked-dropmenu
                  "
                  v-show="isOpenFilter"
                >
                  <div class="drop-item" @click="changeFilterType('NAME')">
                    <p
                      class="
                        support-main__dialogs-header__search-filter__checked-dropmenu__item
                      "
                    >
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.0917 9.09169C12.9087 8.44891 13.505 7.56746 13.7977 6.56997C14.0904 5.57249 14.0649 4.50858 13.7248 3.52625C13.3847 2.54391 12.7469 1.69202 11.9001 1.08907C11.0532 0.486122 10.0395 0.162109 9 0.162109C7.96045 0.162109 6.94676 0.486122 6.09994 1.08907C5.25312 1.69202 4.61528 2.54391 4.27517 3.52625C3.93506 4.50858 3.90959 5.57249 4.2023 6.56997C4.49501 7.56746 5.09134 8.44891 5.90833 9.09169C4.5084 9.65256 3.2869 10.5828 2.37407 11.7833C1.46125 12.9838 0.8913 14.4094 0.724997 15.9084C0.712959 16.0178 0.722594 16.1285 0.753352 16.2342C0.784109 16.3399 0.835387 16.4386 0.904257 16.5245C1.04335 16.6979 1.24565 16.809 1.46666 16.8334C1.68768 16.8577 1.9093 16.7932 2.08277 16.6541C2.25624 16.515 2.36735 16.3127 2.39166 16.0917C2.57465 14.4627 3.3514 12.9582 4.57351 11.8657C5.79562 10.7732 7.37741 10.1692 9.01667 10.1692C10.6559 10.1692 12.2377 10.7732 13.4598 11.8657C14.6819 12.9582 15.4587 14.4627 15.6417 16.0917C15.6643 16.2965 15.762 16.4856 15.9159 16.6225C16.0698 16.7595 16.269 16.8346 16.475 16.8334H16.5667C16.7851 16.8082 16.9848 16.6978 17.1221 16.5261C17.2595 16.3544 17.3234 16.1353 17.3 15.9167C17.1329 14.4135 16.5599 12.9842 15.6424 11.7819C14.7249 10.5795 13.4974 9.64959 12.0917 9.09169ZM9 8.50002C8.34073 8.50002 7.69626 8.30453 7.1481 7.93825C6.59993 7.57198 6.17269 7.05139 5.9204 6.4423C5.66811 5.83321 5.6021 5.16299 5.73071 4.51639C5.85933 3.86979 6.1768 3.27584 6.64297 2.80967C7.10915 2.34349 7.70309 2.02602 8.3497 1.89741C8.9963 1.76879 9.66652 1.8348 10.2756 2.08709C10.8847 2.33938 11.4053 2.76662 11.7716 3.31479C12.1378 3.86295 12.3333 4.50742 12.3333 5.16669C12.3333 6.05074 11.9821 6.89859 11.357 7.52371C10.7319 8.14883 9.88405 8.50002 9 8.50002Z"
                          fill="#2F2F2F"
                        />
                      </svg>
                      По имени
                    </p>
                    <div class="drop-item__select" v-if="filterType === 'NAME'">
                      <svg
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.47346 0.806574C9.41149 0.744088 9.33775 0.694492 9.25651 0.660646C9.17527 0.6268 9.08814 0.609375 9.00013 0.609375C8.91212 0.609375 8.82498 0.6268 8.74374 0.660646C8.6625 0.694492 8.58877 0.744088 8.5268 0.806574L3.56013 5.77991L1.47346 3.68657C1.40911 3.62441 1.33315 3.57554 1.24992 3.54274C1.16668 3.50993 1.0778 3.49385 0.988344 3.49539C0.89889 3.49694 0.810617 3.51609 0.728565 3.55176C0.646513 3.58742 0.572288 3.63889 0.510129 3.70324C0.44797 3.76759 0.399094 3.84355 0.366292 3.92679C0.333489 4.01002 0.317402 4.09891 0.31895 4.18836C0.320497 4.27781 0.339649 4.36609 0.375311 4.44814C0.410974 4.53019 0.462448 4.60441 0.526796 4.66657L3.0868 7.22657C3.14877 7.28906 3.22251 7.33865 3.30374 7.3725C3.38498 7.40635 3.47212 7.42377 3.56013 7.42377C3.64814 7.42377 3.73527 7.40635 3.81651 7.3725C3.89775 7.33865 3.97149 7.28906 4.03346 7.22657L9.47346 1.78657C9.54113 1.72415 9.59514 1.64838 9.63208 1.56404C9.66901 1.47971 9.68808 1.38864 9.68808 1.29657C9.68808 1.20451 9.66901 1.11344 9.63208 1.0291C9.59514 0.94477 9.54113 0.869002 9.47346 0.806574Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="drop-item" @click="changeFilterType('EMAIL')">
                    <p
                      class="
                        support-main__dialogs-header__search-filter__checked-dropmenu__item
                      "
                    >
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.8334 0.833496H3.16675C2.50371 0.833496 1.86782 1.09689 1.39898 1.56573C0.93014 2.03457 0.666748 2.67045 0.666748 3.3335V11.6668C0.666748 12.3299 0.93014 12.9658 1.39898 13.4346C1.86782 13.9034 2.50371 14.1668 3.16675 14.1668H14.8334C15.4965 14.1668 16.1323 13.9034 16.6012 13.4346C17.07 12.9658 17.3334 12.3299 17.3334 11.6668V3.3335C17.3334 2.67045 17.07 2.03457 16.6012 1.56573C16.1323 1.09689 15.4965 0.833496 14.8334 0.833496ZM14.4917 2.50016L9.59175 7.40016C9.51428 7.47827 9.42211 7.54026 9.32056 7.58257C9.21901 7.62488 9.11009 7.64666 9.00008 7.64666C8.89007 7.64666 8.78115 7.62488 8.6796 7.58257C8.57805 7.54026 8.48588 7.47827 8.40841 7.40016L3.50841 2.50016H14.4917ZM15.6667 11.6668C15.6667 11.8878 15.579 12.0998 15.4227 12.2561C15.2664 12.4124 15.0544 12.5002 14.8334 12.5002H3.16675C2.94573 12.5002 2.73377 12.4124 2.57749 12.2561C2.42121 12.0998 2.33341 11.8878 2.33341 11.6668V3.67516L7.23342 8.57516C7.70217 9.04333 8.33758 9.3063 9.00008 9.3063C9.66258 9.3063 10.298 9.04333 10.7667 8.57516L15.6667 3.67516V11.6668Z"
                          fill="#404040"
                        />
                      </svg>
                      По почте
                    </p>
                    <div
                      class="drop-item__select"
                      v-if="filterType === 'EMAIL'"
                    >
                      <svg
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.47346 0.806574C9.41149 0.744088 9.33775 0.694492 9.25651 0.660646C9.17527 0.6268 9.08814 0.609375 9.00013 0.609375C8.91212 0.609375 8.82498 0.6268 8.74374 0.660646C8.6625 0.694492 8.58877 0.744088 8.5268 0.806574L3.56013 5.77991L1.47346 3.68657C1.40911 3.62441 1.33315 3.57554 1.24992 3.54274C1.16668 3.50993 1.0778 3.49385 0.988344 3.49539C0.89889 3.49694 0.810617 3.51609 0.728565 3.55176C0.646513 3.58742 0.572288 3.63889 0.510129 3.70324C0.44797 3.76759 0.399094 3.84355 0.366292 3.92679C0.333489 4.01002 0.317402 4.09891 0.31895 4.18836C0.320497 4.27781 0.339649 4.36609 0.375311 4.44814C0.410974 4.53019 0.462448 4.60441 0.526796 4.66657L3.0868 7.22657C3.14877 7.28906 3.22251 7.33865 3.30374 7.3725C3.38498 7.40635 3.47212 7.42377 3.56013 7.42377C3.64814 7.42377 3.73527 7.40635 3.81651 7.3725C3.89775 7.33865 3.97149 7.28906 4.03346 7.22657L9.47346 1.78657C9.54113 1.72415 9.59514 1.64838 9.63208 1.56404C9.66901 1.47971 9.68808 1.38864 9.68808 1.29657C9.68808 1.20451 9.66901 1.11344 9.63208 1.0291C9.59514 0.94477 9.54113 0.869002 9.47346 0.806574Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="drop-item" @click="changeFilterType('VK_ID')">
                    <p
                      class="
                        support-main__dialogs-header__search-filter__checked-dropmenu__item
                      "
                    >
                      <svg
                        width="15"
                        height="10"
                        viewBox="0 0 15 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.6558 1.1094C14.7602 0.758158 14.6558 0.5 14.1596 0.5H12.5185C12.1013 0.5 11.909 0.722987 11.8047 0.968947C11.8047 0.968947 10.9701 3.02391 9.78791 4.35875C9.40544 4.74516 9.23161 4.86796 9.02297 4.86796C8.91864 4.86796 8.76767 4.74516 8.76767 4.39392V1.1094C8.76767 0.687816 8.64659 0.5 8.29881 0.5H5.72009C5.4594 0.5 5.30257 0.695632 5.30257 0.881079C5.30257 1.28075 5.89356 1.37288 5.95451 2.49693V4.93842C5.95451 5.47368 5.85887 5.57079 5.65023 5.57079C5.09392 5.57079 3.7408 3.50647 2.93811 1.14457C2.78081 0.685447 2.62303 0.5 2.20364 0.5H0.562631C0.0937718 0.5 0 0.722987 0 0.968947C0 1.40805 0.556419 3.58605 2.59045 6.46653C3.94662 8.4335 5.85699 9.5 7.59552 9.5C8.63874 9.5 8.76767 9.26316 8.76767 8.8552V7.36842C8.76767 6.89474 8.86649 6.80024 9.19668 6.80024C9.44025 6.80024 9.85742 6.92316 10.8311 7.87159C11.9437 8.99553 12.1271 9.5 12.753 9.5H14.394C14.8628 9.5 15.0973 9.26316 14.962 8.79575C14.8141 8.32988 14.2829 7.65417 13.5779 6.85293C13.1955 6.39618 12.6217 5.90438 12.448 5.65854C12.2045 5.34235 12.274 5.20191 12.448 4.92101C12.448 4.92101 14.4472 2.07547 14.6558 1.1094Z"
                          fill="#404040"
                        />
                      </svg>
                      По ID Вконтакте
                    </p>
                    <div
                      class="drop-item__select"
                      v-if="filterType === 'VK_ID'"
                    >
                      <svg
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.47346 0.806574C9.41149 0.744088 9.33775 0.694492 9.25651 0.660646C9.17527 0.6268 9.08814 0.609375 9.00013 0.609375C8.91212 0.609375 8.82498 0.6268 8.74374 0.660646C8.6625 0.694492 8.58877 0.744088 8.5268 0.806574L3.56013 5.77991L1.47346 3.68657C1.40911 3.62441 1.33315 3.57554 1.24992 3.54274C1.16668 3.50993 1.0778 3.49385 0.988344 3.49539C0.89889 3.49694 0.810617 3.51609 0.728565 3.55176C0.646513 3.58742 0.572288 3.63889 0.510129 3.70324C0.44797 3.76759 0.399094 3.84355 0.366292 3.92679C0.333489 4.01002 0.317402 4.09891 0.31895 4.18836C0.320497 4.27781 0.339649 4.36609 0.375311 4.44814C0.410974 4.53019 0.462448 4.60441 0.526796 4.66657L3.0868 7.22657C3.14877 7.28906 3.22251 7.33865 3.30374 7.3725C3.38498 7.40635 3.47212 7.42377 3.56013 7.42377C3.64814 7.42377 3.73527 7.40635 3.81651 7.3725C3.89775 7.33865 3.97149 7.28906 4.03346 7.22657L9.47346 1.78657C9.54113 1.72415 9.59514 1.64838 9.63208 1.56404C9.66901 1.47971 9.68808 1.38864 9.68808 1.29657C9.68808 1.20451 9.66901 1.11344 9.63208 1.0291C9.59514 0.94477 9.54113 0.869002 9.47346 0.806574Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="support-main__dialogs-header__search-find">
                <input
                  type="text"
                  placeholder="Введите запрос..."
                  v-model="filterValue"
                  @keyup.enter="filterDialogs"
                />
                <div
                  class="support-main__dialogs-header__search-find__icon"
                  @click="filterDialogs"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.9009 17.5994L15.5 14.2261C16.8201 12.5802 17.4594 10.491 17.2864 8.38814C17.1135 6.2853 16.1414 4.32866 14.5701 2.92054C12.9988 1.51241 10.9477 0.759829 8.83855 0.817543C6.7294 0.875257 4.72253 1.73888 3.23058 3.23082C1.73863 4.72277 0.875013 6.72965 0.817299 8.83879C0.759585 10.9479 1.51216 12.999 2.92029 14.5703C4.32842 16.1416 6.28506 17.1137 8.38789 17.2867C10.4907 17.4597 12.5799 16.8204 14.2259 15.5003L17.5992 18.8736C17.6844 18.9595 17.7858 19.0277 17.8975 19.0743C18.0092 19.1208 18.129 19.1448 18.25 19.1448C18.371 19.1448 18.4909 19.1208 18.6026 19.0743C18.7143 19.0277 18.8157 18.9595 18.9009 18.8736C19.0661 18.7027 19.1584 18.4743 19.1584 18.2365C19.1584 17.9988 19.0661 17.7704 18.9009 17.5994ZM9.08337 15.5003C7.81427 15.5003 6.57367 15.1239 5.51846 14.4189C4.46324 13.7138 3.6408 12.7117 3.15514 11.5392C2.66948 10.3667 2.54241 9.07649 2.79 7.83178C3.03758 6.58707 3.64871 5.44373 4.5461 4.54634C5.44349 3.64896 6.58683 3.03783 7.83154 2.79024C9.07625 2.54265 10.3664 2.66972 11.5389 3.15538C12.7114 3.64105 13.7136 4.46349 14.4186 5.5187C15.1237 6.57392 15.5 7.81451 15.5 9.08361C15.5 10.7854 14.824 12.4175 13.6206 13.6209C12.4173 14.8242 10.7852 15.5003 9.08337 15.5003Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="support-main__dialogs-body">
            <div class="dialog-wrapper">
              <div
                v-for="(dialog, index) in isFiltered
                  ? filteredDialogs
                  : dialogs[siteState]"
                :key="dialog._id"
                class="support-main__dialogs-body__dialog"
                @click="selectDialog(index)"
              >
                <div class="support-main__dialogs-header__body-dialog__avatar">
                  <img src="../../assets/images/support/avatar.png" alt="" />
                </div>
                <div class="support-main__dialogs-body__dialog__content">
                  <p
                    class="support-main__dialogs-body__dialog__content-username"
                  >
                    {{ dialog.details.name }}
                    <img
                      src="./../../assets/images/support/vk-icon.svg"
                      alt=""
                      v-if="dialog.details.vk_id"
                    />
                  </p>
                  <p
                    class="support-main__dialogs-body__dialog__content-message"
                  >
                    <span
                      v-if="
                        dialog.messages[dialog.messages.length - 1].sender ===
                        'operator'
                      "
                      >Вы:</span
                    >
                    <small
                      v-html="
                        parseMessage(
                          dialog.messages[dialog.messages.length - 1].data
                        )
                      "
                      v-if="
                        dialog.messages[dialog.messages.length - 1].type ===
                        'text'
                      "
                    ></small>
                    <span
                      v-if="
                        dialog.messages[dialog.messages.length - 1].type ===
                        'image'
                      "
                      >Изображение</span
                    >
                  </p>
                  <div
                    class="
                      support-main__dialogs-body__dialog__content-unchecked
                    "
                    v-if="dialog.isNew"
                  >
                    <p>!</p>
                  </div>
                  <p class="support-main__dialogs-body__dialog__content-date">
                    {{ parseData(dialog.last_update) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="support-main__chat"
          v-if="(isMobileVersion && isSelectedChat) || !isMobileVersion"
        >
          <div class="support-main__chat-none" v-if="!isSelectedChat">
            <div class="support-main__chat-none__wrapper">
              <div class="support-main__chat-none__wrapper-img">
                <svg
                  width="144"
                  height="144"
                  viewBox="0 0 144 144"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M85.5 27H31.5C29.016 27 27 29.016 27 31.5C27 33.984 29.016 36 31.5 36H85.5C87.984 36 90 33.984 90 31.5C90 29.016 87.984 27 85.5 27Z"
                    fill="#2F2F2F"
                    fill-opacity="0.7"
                  />
                  <path
                    d="M67.5 45H31.5C29.016 45 27 47.016 27 49.5C27 51.984 29.016 54 31.5 54H67.5C69.984 54 72 51.984 72 49.5C72 47.016 69.984 45 67.5 45Z"
                    fill="#2F2F2F"
                    fill-opacity="0.7"
                  />
                  <path
                    d="M99 0H18C8.073 0 0 8.073 0 18V108C0 109.746 1.008 111.339 2.592 112.077C3.195 112.356 3.852 112.5 4.5 112.5C5.535 112.5 6.561 112.14 7.38 111.456L33.129 90H99C108.927 90 117 81.927 117 72V18C117 8.073 108.927 0 99 0ZM108 72C108 76.959 103.968 81 99 81H31.5C30.447 81 29.43 81.369 28.62 82.044L9 98.397V18C9 13.041 13.032 9 18 9H99C103.968 9 108 13.041 108 18V72Z"
                    fill="#2F2F2F"
                    fill-opacity="0.7"
                  />
                  <path
                    d="M126 36C123.516 36 121.5 38.016 121.5 40.5C121.5 42.984 123.516 45 126 45C130.968 45 135 49.041 135 54V130.131L119.808 117.981C119.016 117.351 118.017 117 117 117H54C49.032 117 45 112.959 45 108V103.5C45 101.016 42.984 99 40.5 99C38.016 99 36 101.016 36 103.5V108C36 117.927 44.073 126 54 126H115.416L136.683 143.019C137.502 143.667 138.501 144 139.5 144C140.157 144 140.823 143.856 141.453 143.559C143.01 142.803 144 141.228 144 139.5V54C144 44.073 135.927 36 126 36Z"
                    fill="#2F2F2F"
                    fill-opacity="0.7"
                  />
                </svg>
              </div>
              <div class="support-main__chat-none__wrapper-hint">
                <p>Перейдите в диалог с</p>
                <p>пользователем и начните общение</p>
              </div>
            </div>
          </div>
          <div class="support-main__chat-live" v-else>
            <div class="support-main__chat-live__header">
              <div class="support-main__chat-live__header-left">
                <div
                  class="support-main__chat-live__header-back"
                  @click="isSelectedChat = false"
                >
                  <svg
                    width="12"
                    height="20"
                    viewBox="0 0 12 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.9595 0.480622C11.5225 1.01054 11.5494 1.89657 11.0195 2.45961L3.92252 10.0001L11.0195 17.5406C11.5494 18.1036 11.5225 18.9897 10.9595 19.5196C10.3964 20.0495 9.51042 20.0227 8.9805 19.4596L0.980496 10.9596C0.473136 10.4205 0.473136 9.57966 0.980496 9.04059L8.9805 0.540591C9.51042 -0.0224521 10.3964 -0.0493014 10.9595 0.480622Z"
                      fill="#404040"
                    />
                  </svg>
                </div>
                <p class="support-main__chat-live__header-left__username">
                  {{ this.dialogs[siteState][this.selectedDialogs].details.name }}
                </p>
              </div>
              <div class="support-main__chat-live__header-etc">
                <img src="../../assets/images/support/etc.svg" alt="" @click="isOpenMobileEtc = !isOpenMobileEtc"/>
                <div class="support-main__chat-live__header-etc__modal" v-if="isOpenMobileEtc">
                  <div
                    class="support-main__chat-live__header-right__contact"
                    v-if="this.dialogs[siteState][this.selectedDialogs].details.vk"
                  >
                    <div class="contact__wrapper">
                      <div
                        class="
                          support-main__chat-live__header-right__contact-icon
                        "
                      >
                        <svg
                          width="22"
                          height="12"
                          viewBox="0 0 22 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.4952 0.812527C21.6482 0.344211 21.4952 0 20.7674 0H18.3605C17.7485 0 17.4666 0.297316 17.3136 0.625263C17.3136 0.625263 16.0895 3.36521 14.3556 5.145C13.7946 5.66021 13.5397 5.82395 13.2337 5.82395C13.0807 5.82395 12.8593 5.66021 12.8593 5.1919V0.812527C12.8593 0.250422 12.6817 0 12.1716 0H8.38946C8.00712 0 7.7771 0.260842 7.7771 0.508106C7.7771 1.041 8.64389 1.16384 8.73329 2.66258V5.9179C8.73329 6.63158 8.59301 6.76105 8.287 6.76105C7.47109 6.76105 5.4865 4.00863 4.30923 0.859421C4.07851 0.247263 3.84712 0 3.232 0H0.825193C0.137532 0 0 0.297316 0 0.625263C0 1.21074 0.816081 4.11474 3.79932 7.95537C5.78838 10.578 8.59026 12 11.1401 12C12.6701 12 12.8593 11.6842 12.8593 11.1403V9.1579C12.8593 8.52632 13.0042 8.40032 13.4885 8.40032C13.8457 8.40032 14.4575 8.56421 15.8856 9.82879C17.5175 11.3274 17.7863 12 18.7044 12H21.1112C21.7988 12 22.1427 11.6842 21.9443 11.061C21.7273 10.4398 20.9482 9.53889 19.9143 8.47058C19.3533 7.86158 18.5118 7.20584 18.257 6.87805C17.9 6.45647 18.0019 6.26921 18.257 5.89468C18.257 5.89468 21.1892 2.10063 21.4952 0.812527Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <input
                        :value="this.dialogs[siteState][this.selectedDialogs].details.vk"
                        readonly
                      />
                    </div>
                    <div
                      class="
                        support-main__chat-live__header-right__contact-copy
                      "
                      @click="copy"
                    >
                      <svg
                        width="22"
                        height="24"
                        viewBox="0 0 22 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.25 0C16.157 0 7.24999 0 7.24999 0C5.66298 0 4.25001 1.45576 4.25001 3.00002L3.38301 3.02026C1.79675 3.02026 0.5 4.45574 0.5 6V21C0.5 22.5443 1.91301 24 3.50002 24H14.75C16.337 24 17.75 22.5443 17.75 21H18.5C20.087 21 21.5 19.5443 21.5 18V6.01801L16.25 0ZM14.75 22.5H3.50002C2.71252 22.5 2.00003 21.7643 2.00003 21V6C2.00003 5.23576 2.64128 4.52475 3.42878 4.52475L4.25001 4.50001V18C4.25001 19.5443 5.66298 21 7.24999 21H16.25C16.25 21.7643 15.5375 22.5 14.75 22.5ZM20 18C20 18.7642 19.2875 19.5 18.5 19.5H7.24999C6.46249 19.5 5.75 18.7643 5.75 18V3.00002C5.75 2.23578 6.46249 1.50003 7.24999 1.50003H14.75C14.738 3.22728 14.75 4.51878 14.75 4.51878C14.75 6.07727 16.1525 7.50003 17.75 7.50003C17.75 7.50003 18.5465 7.50003 20 7.50003V18ZM17.75 6C16.9513 6 16.25 4.54877 16.25 3.77026C16.25 3.77026 16.25 2.98125 16.25 1.52326V1.52175L20 6H17.75ZM16.25 10.5135H9.50002C9.08602 10.5135 8.75002 10.8488 8.75002 11.2628C8.75002 11.6768 9.08602 12.012 9.50002 12.012H16.25C16.664 12.012 17 11.6767 17 11.2628C17 10.8488 16.664 10.5135 16.25 10.5135ZM16.25 14.2598H9.50002C9.08602 14.2598 8.75002 14.595 8.75002 15.009C8.75002 15.423 9.08602 15.7582 9.50002 15.7582H16.25C16.664 15.7582 17 15.423 17 15.009C17 14.595 16.664 14.2598 16.25 14.2598Z"
                          fill="#2F2F2F"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    class="support-main__chat-live__header-right__contact"
                    v-if="this.dialogs[siteState][this.selectedDialogs].details.email"
                  >
                    <div class="contact__wrapper">
                      <div
                        class="
                          support-main__chat-live__header-right__contact-icon
                        "
                      >
                        <svg
                          width="22"
                          height="18"
                          viewBox="0 0 22 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.5832 0.333496H3.4165C2.55455 0.333496 1.7279 0.675906 1.11841 1.2854C0.508914 1.89489 0.166504 2.72154 0.166504 3.5835V14.4168C0.166504 15.2788 0.508914 16.1054 1.11841 16.7149C1.7279 17.3244 2.55455 17.6668 3.4165 17.6668H18.5832C19.4451 17.6668 20.2718 17.3244 20.8813 16.7149C21.4908 16.1054 21.8332 15.2788 21.8332 14.4168V3.5835C21.8332 2.72154 21.4908 1.89489 20.8813 1.2854C20.2718 0.675906 19.4451 0.333496 18.5832 0.333496ZM18.139 2.50016L11.769 8.87016C11.6683 8.9717 11.5485 9.05229 11.4165 9.10729C11.2844 9.16229 11.1429 9.19061 10.9998 9.19061C10.8568 9.19061 10.7152 9.16229 10.5832 9.10729C10.4512 9.05229 10.3314 8.9717 10.2307 8.87016L3.86067 2.50016H18.139ZM19.6665 14.4168C19.6665 14.7041 19.5524 14.9797 19.3492 15.1829C19.146 15.386 18.8705 15.5002 18.5832 15.5002H3.4165C3.12919 15.5002 2.85364 15.386 2.65047 15.1829C2.44731 14.9797 2.33317 14.7041 2.33317 14.4168V4.02766L8.70317 10.3977C9.31255 11.0063 10.1386 11.3481 10.9998 11.3481C11.8611 11.3481 12.6871 11.0063 13.2965 10.3977L19.6665 4.02766V14.4168Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <input
                        :value="
                          this.dialogs[siteState][this.selectedDialogs].details.email
                        "
                        readonly
                      />
                    </div>
                    <div
                      class="
                        support-main__chat-live__header-right__contact-copy
                      "
                      @click="copy"
                    >
                      <svg
                        width="22"
                        height="24"
                        viewBox="0 0 22 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.25 0C16.157 0 7.24999 0 7.24999 0C5.66298 0 4.25001 1.45576 4.25001 3.00002L3.38301 3.02026C1.79675 3.02026 0.5 4.45574 0.5 6V21C0.5 22.5443 1.91301 24 3.50002 24H14.75C16.337 24 17.75 22.5443 17.75 21H18.5C20.087 21 21.5 19.5443 21.5 18V6.01801L16.25 0ZM14.75 22.5H3.50002C2.71252 22.5 2.00003 21.7643 2.00003 21V6C2.00003 5.23576 2.64128 4.52475 3.42878 4.52475L4.25001 4.50001V18C4.25001 19.5443 5.66298 21 7.24999 21H16.25C16.25 21.7643 15.5375 22.5 14.75 22.5ZM20 18C20 18.7642 19.2875 19.5 18.5 19.5H7.24999C6.46249 19.5 5.75 18.7643 5.75 18V3.00002C5.75 2.23578 6.46249 1.50003 7.24999 1.50003H14.75C14.738 3.22728 14.75 4.51878 14.75 4.51878C14.75 6.07727 16.1525 7.50003 17.75 7.50003C17.75 7.50003 18.5465 7.50003 20 7.50003V18ZM17.75 6C16.9513 6 16.25 4.54877 16.25 3.77026C16.25 3.77026 16.25 2.98125 16.25 1.52326V1.52175L20 6H17.75ZM16.25 10.5135H9.50002C9.08602 10.5135 8.75002 10.8488 8.75002 11.2628C8.75002 11.6768 9.08602 12.012 9.50002 12.012H16.25C16.664 12.012 17 11.6767 17 11.2628C17 10.8488 16.664 10.5135 16.25 10.5135ZM16.25 14.2598H9.50002C9.08602 14.2598 8.75002 14.595 8.75002 15.009C8.75002 15.423 9.08602 15.7582 9.50002 15.7582H16.25C16.664 15.7582 17 15.423 17 15.009C17 14.595 16.664 14.2598 16.25 14.2598Z"
                          fill="#2F2F2F"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="support-main__chat-live__header-right">
                <div
                  class="support-main__chat-live__header-right__contact"
                  v-if="this.dialogs[siteState][this.selectedDialogs].details.vk"
                >
                  <div class="contact__wrapper">
                    <div
                      class="
                        support-main__chat-live__header-right__contact-icon
                      "
                    >
                      <svg
                        width="22"
                        height="12"
                        viewBox="0 0 22 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M21.4952 0.812527C21.6482 0.344211 21.4952 0 20.7674 0H18.3605C17.7485 0 17.4666 0.297316 17.3136 0.625263C17.3136 0.625263 16.0895 3.36521 14.3556 5.145C13.7946 5.66021 13.5397 5.82395 13.2337 5.82395C13.0807 5.82395 12.8593 5.66021 12.8593 5.1919V0.812527C12.8593 0.250422 12.6817 0 12.1716 0H8.38946C8.00712 0 7.7771 0.260842 7.7771 0.508106C7.7771 1.041 8.64389 1.16384 8.73329 2.66258V5.9179C8.73329 6.63158 8.59301 6.76105 8.287 6.76105C7.47109 6.76105 5.4865 4.00863 4.30923 0.859421C4.07851 0.247263 3.84712 0 3.232 0H0.825193C0.137532 0 0 0.297316 0 0.625263C0 1.21074 0.816081 4.11474 3.79932 7.95537C5.78838 10.578 8.59026 12 11.1401 12C12.6701 12 12.8593 11.6842 12.8593 11.1403V9.1579C12.8593 8.52632 13.0042 8.40032 13.4885 8.40032C13.8457 8.40032 14.4575 8.56421 15.8856 9.82879C17.5175 11.3274 17.7863 12 18.7044 12H21.1112C21.7988 12 22.1427 11.6842 21.9443 11.061C21.7273 10.4398 20.9482 9.53889 19.9143 8.47058C19.3533 7.86158 18.5118 7.20584 18.257 6.87805C17.9 6.45647 18.0019 6.26921 18.257 5.89468C18.257 5.89468 21.1892 2.10063 21.4952 0.812527Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <input
                      :value="this.dialogs[siteState][this.selectedDialogs].details.vk"
                      readonly
                    />
                  </div>
                  <div
                    class="support-main__chat-live__header-right__contact-copy"
                    @click="copy"
                  >
                    <svg
                      width="22"
                      height="24"
                      viewBox="0 0 22 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.25 0C16.157 0 7.24999 0 7.24999 0C5.66298 0 4.25001 1.45576 4.25001 3.00002L3.38301 3.02026C1.79675 3.02026 0.5 4.45574 0.5 6V21C0.5 22.5443 1.91301 24 3.50002 24H14.75C16.337 24 17.75 22.5443 17.75 21H18.5C20.087 21 21.5 19.5443 21.5 18V6.01801L16.25 0ZM14.75 22.5H3.50002C2.71252 22.5 2.00003 21.7643 2.00003 21V6C2.00003 5.23576 2.64128 4.52475 3.42878 4.52475L4.25001 4.50001V18C4.25001 19.5443 5.66298 21 7.24999 21H16.25C16.25 21.7643 15.5375 22.5 14.75 22.5ZM20 18C20 18.7642 19.2875 19.5 18.5 19.5H7.24999C6.46249 19.5 5.75 18.7643 5.75 18V3.00002C5.75 2.23578 6.46249 1.50003 7.24999 1.50003H14.75C14.738 3.22728 14.75 4.51878 14.75 4.51878C14.75 6.07727 16.1525 7.50003 17.75 7.50003C17.75 7.50003 18.5465 7.50003 20 7.50003V18ZM17.75 6C16.9513 6 16.25 4.54877 16.25 3.77026C16.25 3.77026 16.25 2.98125 16.25 1.52326V1.52175L20 6H17.75ZM16.25 10.5135H9.50002C9.08602 10.5135 8.75002 10.8488 8.75002 11.2628C8.75002 11.6768 9.08602 12.012 9.50002 12.012H16.25C16.664 12.012 17 11.6767 17 11.2628C17 10.8488 16.664 10.5135 16.25 10.5135ZM16.25 14.2598H9.50002C9.08602 14.2598 8.75002 14.595 8.75002 15.009C8.75002 15.423 9.08602 15.7582 9.50002 15.7582H16.25C16.664 15.7582 17 15.423 17 15.009C17 14.595 16.664 14.2598 16.25 14.2598Z"
                        fill="#2F2F2F"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  class="support-main__chat-live__header-right__contact"
                  v-if="this.dialogs[siteState][this.selectedDialogs].details.email"
                >
                  <div class="contact__wrapper">
                    <div
                      class="
                        support-main__chat-live__header-right__contact-icon
                      "
                    >
                      <svg
                        width="22"
                        height="18"
                        viewBox="0 0 22 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.5832 0.333496H3.4165C2.55455 0.333496 1.7279 0.675906 1.11841 1.2854C0.508914 1.89489 0.166504 2.72154 0.166504 3.5835V14.4168C0.166504 15.2788 0.508914 16.1054 1.11841 16.7149C1.7279 17.3244 2.55455 17.6668 3.4165 17.6668H18.5832C19.4451 17.6668 20.2718 17.3244 20.8813 16.7149C21.4908 16.1054 21.8332 15.2788 21.8332 14.4168V3.5835C21.8332 2.72154 21.4908 1.89489 20.8813 1.2854C20.2718 0.675906 19.4451 0.333496 18.5832 0.333496ZM18.139 2.50016L11.769 8.87016C11.6683 8.9717 11.5485 9.05229 11.4165 9.10729C11.2844 9.16229 11.1429 9.19061 10.9998 9.19061C10.8568 9.19061 10.7152 9.16229 10.5832 9.10729C10.4512 9.05229 10.3314 8.9717 10.2307 8.87016L3.86067 2.50016H18.139ZM19.6665 14.4168C19.6665 14.7041 19.5524 14.9797 19.3492 15.1829C19.146 15.386 18.8705 15.5002 18.5832 15.5002H3.4165C3.12919 15.5002 2.85364 15.386 2.65047 15.1829C2.44731 14.9797 2.33317 14.7041 2.33317 14.4168V4.02766L8.70317 10.3977C9.31255 11.0063 10.1386 11.3481 10.9998 11.3481C11.8611 11.3481 12.6871 11.0063 13.2965 10.3977L19.6665 4.02766V14.4168Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <input
                      :value="this.dialogs[siteState][this.selectedDialogs].details.email"
                      readonly
                    />
                  </div>
                  <div
                    class="support-main__chat-live__header-right__contact-copy"
                    @click="copy"
                  >
                    <svg
                      width="22"
                      height="24"
                      viewBox="0 0 22 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.25 0C16.157 0 7.24999 0 7.24999 0C5.66298 0 4.25001 1.45576 4.25001 3.00002L3.38301 3.02026C1.79675 3.02026 0.5 4.45574 0.5 6V21C0.5 22.5443 1.91301 24 3.50002 24H14.75C16.337 24 17.75 22.5443 17.75 21H18.5C20.087 21 21.5 19.5443 21.5 18V6.01801L16.25 0ZM14.75 22.5H3.50002C2.71252 22.5 2.00003 21.7643 2.00003 21V6C2.00003 5.23576 2.64128 4.52475 3.42878 4.52475L4.25001 4.50001V18C4.25001 19.5443 5.66298 21 7.24999 21H16.25C16.25 21.7643 15.5375 22.5 14.75 22.5ZM20 18C20 18.7642 19.2875 19.5 18.5 19.5H7.24999C6.46249 19.5 5.75 18.7643 5.75 18V3.00002C5.75 2.23578 6.46249 1.50003 7.24999 1.50003H14.75C14.738 3.22728 14.75 4.51878 14.75 4.51878C14.75 6.07727 16.1525 7.50003 17.75 7.50003C17.75 7.50003 18.5465 7.50003 20 7.50003V18ZM17.75 6C16.9513 6 16.25 4.54877 16.25 3.77026C16.25 3.77026 16.25 2.98125 16.25 1.52326V1.52175L20 6H17.75ZM16.25 10.5135H9.50002C9.08602 10.5135 8.75002 10.8488 8.75002 11.2628C8.75002 11.6768 9.08602 12.012 9.50002 12.012H16.25C16.664 12.012 17 11.6767 17 11.2628C17 10.8488 16.664 10.5135 16.25 10.5135ZM16.25 14.2598H9.50002C9.08602 14.2598 8.75002 14.595 8.75002 15.009C8.75002 15.423 9.08602 15.7582 9.50002 15.7582H16.25C16.664 15.7582 17 15.423 17 15.009C17 14.595 16.664 14.2598 16.25 14.2598Z"
                        fill="#2F2F2F"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="support-main__chat-live__dialog">
              <div class="dialog-wrapper">
                <div class="dialog-scroll">
                  <p class="support-main__chat-live__dialog-date">
                    {{
                      parseMonthDate(
                        this.dialogs[siteState][this.selectedDialogs].last_update
                      )
                    }}
                  </p>
                  <div
                    v-for="message in this.dialogs[siteState][this.selectedDialogs]
                      .messages"
                    :key="message.datetime"
                    class="support-main__chat-live__dialog-message"
                    :class="{ 'user-message': message.sender === 'user' }"
                  >
                    <div
                      class="support-main__chat-live__dialog-message__avatar"
                      v-if="message.sender === 'user'"
                    >
                      <img
                        :src="
                          message.sender === 'user'
                            ? require('../../assets/images/support/avatar.png')
                            : require('../../assets/images/support/bot-avatar.svg')
                        "
                        alt=""
                      />
                    </div>
                    <div
                      class="support-main__chat-live__dialog-message__content"
                    >
                      <pre
                        v-html="parseMessage(message.data)"
                        v-if="message.type === 'text'"
                      ></pre>
                      <img
                        v-if="message.type === 'image'"
                        @click="openFullImage(message.data)"
                        :src="message.data"
                        alt=""
                      />
                      <div
                        class="
                          support-main__chat-live__dialog-message__content-date
                        "
                      >
                        <p>{{ parseData(message.datetime) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="support-main__chat-live__dialog-input">
                <input
                  type="text"
                  placeholder="Введите сообщение..."
                  v-model="messageText"
                  @keyup.enter="sendMessage"
                />
                <div class="left-input-area">
                  <div class="support-main__chat-live__dialog-input-add">
                    <input
                      v-show="false"
                      id="img-upload"
                      @change="sendImage"
                      type="file"
                      name="file"
                      autocomplete="off"
                      required
                    />
                    <label for="img-upload">
                      <img
                        src="../../assets/images/support/addPhoto.svg"
                        alt=""
                      />
                    </label>
                  </div>
                  <div class="support-main__chat-live__dialog-input-send" >
                    <img src="../../assets/images/support/send.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./support.scss";
import Navigation from "@/components/Navigation/Navigation";

import PaymentSearch from "@/components/paymentSearch/PaymentSearch";
import UserSearch from "@/components/userSearch/UserSearch";
import PopupExit from "@/components/PopupExit/PopupExit";

import Loader from '@/components/Loader/Loader'

import io from 'socket.io-client'

export default {
  name: "Support",
  data() {
    return {
      isOpenMobileEtc: false,
      isMobileVersion: false,
      isOpenFilter: false,
      isSelectedChat: false,
      messageText: "",
      dialogs: {
        starpets: [],
        rbxtree: []
      },
      filteredDialogs: [],
      selectedDialogs: null,
      selectedDialogID: "",
      filterType: "NAME",
      filterValue: "",
      isFiltered: false,
      smileMap: {
        SMILE_1: "SMILE_1.png",
        SMILE_2: "SMILE_2.png",
        SMILE_3: "SMILE_3.png",
        SMILE_4: "SMILE_4.png",
        SMILE_5: "SMILE_5.png",
      },
      filterTypeMap: {
        NAME: "Имя",
        EMAIL: "Почта",
        VK_ID: "ВК ID",
      },
      fullImage: null,
      showFullImage: false,
      chatUrlMap: {
        rbxtree: 'live-chat.backend-tree.com',
        starpets: 'chat.starpets.gg',
      },
      siteState: '',
      sockets: {
        rbxtree: null,
        starpets: null
      },
      loading: false
    }
  },
  components: {
    PaymentSearch,
    UserSearch,
    PopupExit,
    Navigation,
    Loader
  },
  watch: {
    siteState: async function () {
      this.isSelectedChat = false
      this.selectedDialogs = null
    }
  },
  updated () {
    this.siteState = this.$store.state.toggleSite
  },
  created () {
    this.siteState = this.$store.state.toggleSite

    this.initSocket('rbxtree');
    this.initDialog('rbxtree');

    this.initSocket('starpets');
    this.initDialog('starpets');
  },
  methods: {
    initDialog: async function (project) {
      this.loading = true

      const baseUrl = this.chatUrlMap[project]
      const response = await fetch(`https://${baseUrl}/api/v1/support/fetch_dialogs`, {
        headers: {
          'SUPPORT-KEY': 'SJFjsdgj240g0sdjgjsdgjsdgSD1239rusdg'
        }
      })

      const data = await response.json()

      if (response.status === 200) {
        this.dialogs[project] = data.topups;
      }

      this.loading = false
    },
    initSocket(project) {
      const baseUrl = this.chatUrlMap[project]

      this.sockets[project] = io(`https://${baseUrl}`, {
        transports: ['websocket'],
        query: 'Admin=SJFjsdgj240g0sdjgjsdgjsdgSD1239rusdg'
      })

      this.sockets[project].on("connect", () => {
        console.log("Connect");
      });

      this.sockets[project].on("update_dialog", (dialog) => {
        this.dialogs[project] = this.dialogs[project].filter((d) => d._id !== dialog._id);

        dialog.isNew = true;
        this.dialogs[project].unshift(dialog);

        if (project === this.siteState) {
          if (dialog._id === this.selectedDialogID) {
            this.selectedDialogs = 0;
          } else if (this.selectedDialogs === 0) {
            this.selectedDialogs += 1;
          }
        }

        if (
          dialog._id === this.selectedDialogID ||
          dialog.messages[dialog.messages.length - 1].sender === "operator"
        ) {
          this.dialogs[project][this.selectedDialogs].isNew = false;

          setTimeout(() => {
            this.chatScroll();
          }, 1);
        }
      });
    },
    copy(event) {
      const text = event.target
        .closest(".support-main__chat-live__header-right__contact-copy")
        .parentNode.querySelector(".contact__wrapper input");
      text.select();
      document.execCommand("copy");
    },
    selectDialog(dialogIndex) {
      this.isSelectedChat = true;
      this.selectedDialogs = dialogIndex;

      this.dialogs[this.siteState][this.selectedDialogs].isNew = false;
      this.selectedDialogID = this.dialogs[this.siteState][this.selectedDialogs]._id;

      setTimeout(() => {
        this.chatScroll();
      }, 1);
    },
    openFullImage(src) {
      this.fullImage = src;
      this.showFullImage = true;
    },
    closeFullImage() {
      this.fullImage = "";
      this.showFullImage = false;
    },
    sendMessage() {
      if (this.messageText === "") {
        return;
      }

      this.sockets[this.siteState].emit("a_push", {
        Dialog: this.dialogs[this.siteState][this.selectedDialogs]._id,
        Admin: "SJFjsdgj240g0sdjgjsdgjsdgSD1239rusdg",
        message: {
          type: "text",
          data: this.messageText,
        },
      });

      const d = new Date();
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset());

      const newDialog = this.dialogs[this.siteState][this.selectedDialogs];

      this.dialogs[this.siteState] = this.dialogs[this.siteState].filter((d) => d._id !== newDialog._id);

      newDialog.messages.push({
        data: this.messageText,
        datetime: d.toISOString(),
        sender: "operator",
        type: "text",
      });

      this.dialogs[this.siteState].unshift(newDialog);
      this.selectedDialogs = 0;

      this.messageText = "";
      setTimeout(() => {
        this.chatScroll();
      }, 1);
    },
    sendImage: async function (event) {
      const file = event.target.files[0];
      const formData = new FormData();

      formData.append("file", file, file.name);

      const response = await fetch("https://imgs.backend-tree.com", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();

      if (data.filename) {
        this.sockets[this.siteState].emit("a_push", {
          Dialog: this.dialogs[this.siteState][this.selectedDialogs]._id,
          Admin: "SJFjsdgj240g0sdjgjsdgjsdgSD1239rusdg",
          message: {
            type: "image",
            data: `https://imgs.backend-tree.com/static/uploads/${data.filename}`,
          },
        });

        const d = new Date();
        d.setMinutes(d.getMinutes() + d.getTimezoneOffset());

        this.dialogs[this.siteState][this.selectedDialogs].messages.push({
          data: `https://imgs.backend-tree.com/static/uploads/${data.filename}`,
          datetime: d.toISOString(),
          sender: "operator",
          type: "image",
        });

        setTimeout(() => {
          this.chatScroll();
        }, 1);
      }
    },
    parseMessage(message) {
      let parsedMessage = message;

      Object.keys(this.smileMap).forEach((k) => {
        parsedMessage = parsedMessage.replaceAll(
          `|${k}|`,
          `<img src="/smiles/${this.smileMap[k]}" />`
        );
      });

      return parsedMessage;
    },
    parseData(datetime) {
      const d = new Date(datetime);
      d.setMinutes(d.getMinutes() - d.getTimezoneOffset());

      const hours = d.getHours();
      const minutes = d.getMinutes();

      const hoursParse = ("0" + hours).substr(-2);
      const minutesParse = ("0" + minutes).substr(-2);

      return `${hoursParse}:${minutesParse}`;
    },
    parseMonthDate(datetime) {
      const d = new Date(datetime);
      d.setMinutes(d.getMinutes() - d.getTimezoneOffset());

      const day = d.getDate();
      const month = d.getMonth() + 1;

      const dayParse = ("0" + day).substr(-2);
      const monthParse = ("0" + month).substr(-2);

      return `${dayParse}.${monthParse}`;
    },
    changeFilterType(ft) {
      this.filterType = ft;

      this.isOpenFilter = false;
    },
    filterDialogs() {
      if (!this.filterValue) {
        this.isFiltered = false;
        return;
      }

      this.filteredDialogs = this.dialogs[this.siteState].filter((d) => {
        const lowerFilterType = this.filterType?.toLowerCase();
        const lowerDialogValue = d.details[lowerFilterType]?.toLowerCase();

        if (lowerDialogValue === undefined) {
          return false;
        }

        return lowerDialogValue.indexOf(this.filterValue.toLowerCase()) !== -1;
      });

      this.isFiltered = true;
      this.filterValue = "";
    },
    chatScroll() {
      const scrollTarget = document.querySelector(
        ".dialog-scroll .support-main__chat-live__dialog-message:last-child"
      );

      const topOffset = 0;
      const elementPosition =
        scrollTarget.getBoundingClientRect().bottom +
        scrollTarget.getBoundingClientRect().height;
      const offsetPosition = elementPosition - topOffset;

      document
        .querySelector(".support-main__chat-live__dialog .dialog-wrapper")
        .scrollBy({
          top: offsetPosition,
          behavior: "smooth",
        });
    },
  },
  mounted() {
    if (document.body.clientWidth <= 1230) this.isMobileVersion = true;
  },
};
</script>
