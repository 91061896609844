import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store/store";

import Auth from "@/views/Auth/Auth";
import Home from "@/views/Home/Home";
import Support from "@/views/Support/Support";
// import NavigationLayout from "@/NavigationLayout/NavigationLayout"

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      console.log('dashboard')
      store.state.currentPage = 'dashboard'
      next()
    }
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    beforeEnter: (to, from, next) => {
      console.log('support')
      store.state.currentPage = 'support'
      next()
    }
  },
  {
    path: "/",
    name: "Auth",
    component: Auth,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const key = localStorage.getItem("bleachInfo");
  if (key === store.state.data.bleachInfo) {
    if (to.path === "/") next({ path: "/dashboard" });
    next();
  } else if (to.path === "/") next();
  else next({ path: "/" });
});

export default router;
