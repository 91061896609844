<template>
  <div
    class="home"
    :class="{
      'rbxtree-bg': $store.state.toggleSite === 'rbxtree',
      'rbxpier-bg': $store.state.toggleSite === 'rbxpier' || $store.state.toggleSite === 'rbxsea',
    }"
  >
    <Navigation />
    <CookieChecker v-if="$store.state.isOpenCookieChecker" />
    <PopupExit v-if="$store.state.isOpenExitPopup" />
    <UserSearch v-if="$store.state.isOpenUserSearch" />
    <PaymentSearch v-if="$store.state.isOpenPaymentSearch" />
    <div class="home-wrapper">
      <div class="header">
        <div class="header__menu" @click="$store.state.openBurgerMenu = true">
          <img src="../../assets/images/home/burger.svg" alt="menu-icon" />
        </div>
        <div class="header__content">
          <p v-if="$store.state.toggleSite === 'rbxtree'">
            {{ $store.state.currentTub.rbxtree }}
          </p>
          <p v-if="$store.state.toggleSite === 'rbxpier'" class="blue-header">
            {{ $store.state.currentTub.rbxpier }}
          </p>
        </div>
      </div>
      <template v-if="$store.state.toggleSite === 'rbxtree'">
        <Replanishment v-if="$store.state.currentTub.rbxtree === 'topups'" />
        <Transfer v-if="$store.state.currentTub.rbxtree === 'transfer'" />
        <LogPassPremium v-if="$store.state.currentTub.rbxtree === 'logpass'" />
        <Premium v-if="$store.state.currentTub.rbxtree === 'premium'" />
      </template>
      <template v-if="$store.state.toggleSite === 'rbxpier'">
        <ReplanishmentPier
          v-if="$store.state.currentTub.rbxpier === 'topups'"
        />
        <TransferPier v-if="$store.state.currentTub.rbxpier === 'transfer'" />
        <LogPassPremiumPier v-if="$store.state.currentTub.rbxpier === 'logpass'" />
        <PremiumPier v-if="$store.state.currentTub.rbxpier === 'premium'" />
      </template>
      <template v-if="$store.state.toggleSite === 'rbxsea'">
        <ReplanishmentSea v-if="$store.state.currentTub.rbxsea === 'topups'" />
        <TransferSea v-if="$store.state.currentTub.rbxsea === 'transfer'" />
        <LogPassPremiumSea
          v-if="$store.state.currentTub.rbxsea === 'logpass'"
        />
        <PremiumSea v-if="$store.state.currentTub.rbxsea === 'premium'" />
      </template>
    </div>
  </div>
</template>

<script>
import "./home.scss";

import Navigation from "@/components/Navigation/Navigation";

import Replanishment from "@/components/rbxTree/Replanishment/Replanishment";
import Transfer from "@/components/rbxTree/Transfer/Transfer";
import LogPassPremium from "@/components/rbxTree/LogPass/LogPassPremium";
import Premium from "@/components/rbxTree/Premium/Premium";

import ReplanishmentPier from "@/components/rbxPier/ReplanishmentPier/ReplanishmentPier";
import TransferPier from "@/components/rbxPier/Transfer/TransferPier";

import ReplanishmentSea from "@/components/rbxSea/Replanishment/ReplanishmentSea";
import TransferSea from "@/components/rbxSea/Transfer/TransferSea";
import LogPassPremiumSea from "@/components/rbxSea/LogPass/LogPassPremiumSea";
import PremiumSea from "@/components/rbxSea/Premium/PremiumSea";

import PaymentSearch from "@/components/paymentSearch/PaymentSearch";
import CookieChecker from "@/components/cookieChecker/CookieChecker";
import UserSearch from "@/components/userSearch/UserSearch";
import PopupExit from "@/components/PopupExit/PopupExit";
import LogPassPremiumPier from "@/components/rbxPier/LogPass/LogPassPremium.vue";
import PremiumPier from "@/components/rbxPier/Premium/Premium.vue";

export default {
  name: "Home",
  components: {
      PremiumPier,
      LogPassPremiumPier,
    Replanishment,
    Transfer,
    LogPassPremium,
    PopupExit,
    TransferPier,
    ReplanishmentPier,
    UserSearch,
    Premium,
    PaymentSearch,
    Navigation,
    CookieChecker,
    TransferSea,
    ReplanishmentSea,
    LogPassPremiumSea,
    PremiumSea,
  },
  data() {
    return {
      totalPages: 20,
      key: 0,
    };
  },
};
</script>
